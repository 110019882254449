import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Typography } from '@material-ui/core'
import Modal from 'views/Modal'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50
  },
  textField: {
    margin: 0,
    width: 'calc( 100% - 80px)',
    marginLeft: 20
  },
  error: {
    color: 'red'
  },
  code: {
    width: 60,
    margin: 0
  }
})

class DeleteSlotConfirmation extends Component {
  render () {
    const { classes, onCancel, isOpen, slot = {}, onSubmit } = this.props
    const child = <div className={classes.container}>
      <Typography className={classes.warning} variant='p'>{`Do you want to delete this slot. It will delete the slots of ${slot && slot.days ? slot.days.length : '0'} ${slot && slot.days && slot.days.length > 1 ? 'days' : 'day'}. If you want to delete only this days slot, use edit option.`}</Typography>
    </div>
    return (
      <Modal isOpen={isOpen} title={'Delete Slot'} onCancel={onCancel} okText={'Confirm'} onSubmit={onSubmit} child={child} />
    )
  }
}

DeleteSlotConfirmation.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

export default withStyles(styles)(DeleteSlotConfirmation)
