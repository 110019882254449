export default theme => ({
  list: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    padding: 5
  },
  progressWrapper: {
    zIndex: 10,
    width: '100%',
    position: 'absolute',
    top: '500',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center'
  }
})
