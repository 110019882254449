import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Typography, withStyles } from '@material-ui/core'

import { Paper } from 'components'

// Component styles
import styles from './style'

class ShopStatus extends Component {
  render () {
    const { classes, className, status, ...rest } = this.props

    const rootClassName = classNames(classes.root, className)

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
              Verified
            </Typography>
            <img className={classes.statusIcon} src={status.verification_status ? '/images/check.png' : '/images/cross.png'} alt='check-status' />
          </div>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
              Accepting Orders
            </Typography>
            <img className={classes.statusIcon} src={status.is_accepting_orders ? '/images/check.png' : '/images/cross.png'} alt='check-status' />
          </div>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
              Active
            </Typography>
            <img className={classes.statusIcon} src={status.is_active ? '/images/check.png' : '/images/cross.png'} alt='check-status' />
          </div>
        </div>
      </Paper>
    )
  }
}

ShopStatus.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ShopStatus)
