import React from 'react'
import { compose } from 'recompose'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from 'react-google-maps'

const MapContainer = compose(withScriptjs, withGoogleMap)(props => {
  const defaultCenter = {
    lat: props.shops && props.shops.length ? props.shops[0].location[0] : 8.4998965,
    lng: props.shops && props.shops.length ? props.shops[0].location[1] : 76.8541258
  }

  return (
    <GoogleMap defaultZoom={8} defaultCenter={defaultCenter}>
      {props.shops.map(marker => {
        return (
          <Marker
            key={marker.id}
            onClick={() => props.onSelectShop(marker)}
            position={{ lat: marker.location[0], lng: marker.location[1] }}
          >
            {props.selectedShop === marker &&
              <InfoWindow>
                <div onClick={() => props.gotoShop(marker.id)} style={{ color: 'blue', cursor: 'pointer' }}>
                  {marker.name}
                </div>
              </InfoWindow>
            }
          </Marker>
        )
      })}
    </GoogleMap>
  )
})

export default MapContainer
