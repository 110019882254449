// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const {
  userLogin,
  userLogout: logOut,
  refreshToken,
  setAuthenticated
} = createActions({
  [ActionTypes.USER_LOGIN]: (email, password) => ({ email, password }),
  [ActionTypes.USER_LOGOUT]: () => ({}),
  [ActionTypes.REFRESH_TOKEN]: () => ({}),
  [ActionTypes.SET_AUTHENTICATED]: () => ({})
})
