import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid, withStyles, CircularProgress, Typography } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
// import treeChanges from 'tree-changes'

import { STATUS } from 'redux/constants/index'

import style from './style'
import { getShopAnalytics } from 'redux/actions'
import { AnalyticsStatus } from '..'

class Details extends Component {
  componentWillMount = () => {
    const { dispatch, shopId } = this.props
    dispatch(getShopAnalytics(shopId))
  }

  render = () => {
    const { classes, analytics } = this.props
    console.log(analytics)
    if (analytics.status === STATUS.RUNNING || analytics.status === STATUS.IDLE) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )
    }
    if (analytics.status === STATUS.ERROR) {
      return (
        <Typography variant='h6'>There are no shops available for this details</Typography>
      )
    }
    return (
      <React.Fragment>
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <AnalyticsStatus details={analytics.data || {}} />
        </Grid>
      </React.Fragment>
    )
  }
}

Details.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    analytics: state.shops.shopAnalytics
  }
}

export default compose(
  withRouter,
  withStyles(style)
)(connect(mapStateToProps)(Details))
