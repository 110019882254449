export default theme => ({
  root: {
    padding: '15px 20px',
    borderColor: '#0767DB'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    height: 112
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    color: 'black',
    fontWeight: 700,
    width: 'calc( 100% - 30px )',
    lineHeight: '20px',
    fontSize: 14
  },
  statusIcon: {
    width: 20,
    height: 20,
    float: 'right'
  }
})
