/**
 * @module Sagas/App
 * @desc App
 */
import { all, put, takeLatest, delay } from 'redux-saga/effects'

import { ActionTypes } from 'redux/constants/index'

/**
 * Switch Menu
 *
 * @param {Object} action
 *
 */

// function* delay(ms, result = ms) {
//   return new Promise(resolve => {
//     setTimeout(() => resolve(result), ms)
//   })
// }
export function * showAlert ({ payload }) {
  try {
    // yield call(delay, 5000)
    yield delay(5000)
    yield put({
      type: ActionTypes.HIDE_ALERT
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.EXCEPTION,
      payload: err
    })
  }
}

/**
 * App Sagas
 */
export default function * root () {
  yield all([takeLatest(ActionTypes.SHOW_ALERT, showAlert)])
}
