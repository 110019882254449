import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'
// import treeChanges from 'tree-changes'

import { STATUS } from 'redux/constants/index'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  withStyles
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { DeleteShopConfirmation } from 'views/ShopDetails/components'
import { showAlert, deleteShop } from 'redux/actions'
import { Portlet, PortletContent } from 'components'
import styles from './style'
import { getResizedImage } from 'services/utility'
// import { DeleteConfirmation } from '..'

class ShopList extends Component {
  state = {
    selectedUsers: [],
    rowsPerPage: 10,
    page: 0,
    showDelete: false
  };

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('delete.status', STATUS.READY)) {
      this.props.dispatch(showAlert('Shop Successfully Deleted', { variant: 'success' }))
      this.hideDeleteShop()
    } else if (changedTo('delete.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.delete.message, { variant: 'error' }))
    }
  }

  handleSelectAll = event => {
    const { users, onSelect } = this.props

    let selectedUsers

    if (event.target.checked) {
      selectedUsers = users.map(user => user.id)
    } else {
      selectedUsers = []
    }

    this.setState({ selectedUsers })

    onSelect(selectedUsers)
  };

  handleSelectOne = (event, id) => {
    const { onSelect } = this.props
    const { selectedUsers } = this.state

    const selectedIndex = selectedUsers.indexOf(id)
    let newSelectedUsers = []

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id)
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1))
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      )
    }

    this.setState({ selectedUsers: newSelectedUsers })

    onSelect(newSelectedUsers)
  };

  handleChangePage = (event, page) => {
    this.setState({ page })
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  showShopDetails = (shop) => {
    const { history } = this.props
    history.push(`/shops/${shop.id}`)
  }

  onReachedBottom = () => {
    this.props.fetchNextPage()
  }

  getStatus = status => {
    const { classes } = this.props
    return <img className={classes.statusIcon} src={status ? 'images/check.png' : 'images/cross.png'} alt='check-status' />
  }

  hideDeleteShop = () => {
    this.setState({ showDelete: false, selectedShop: null })
  }

  showDelete = (e, shop) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ showDelete: true, selectedShop: shop })
  }

  deleteShop = id => {
    this.props.dispatch(deleteShop(id))
  }

  render () {
    const { classes, className, shops } = this.props
    const { selectedUsers } = this.state

    const rootClassName = classNames(classes.root, className)
    return (
      <Portlet className={rootClassName}>
        <PortletContent className={classes.container} noPadding>
          <PerfectScrollbar onYReachEnd={this.onReachedBottom}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.heading} align='left'>Logo</TableCell>
                  <TableCell className={classes.heading} align='left'>Name</TableCell>
                  <TableCell className={classes.heading} align='left'>Phone</TableCell>
                  <TableCell className={classes.heading} align='left'>Address</TableCell>
                  <TableCell className={classes.heading} align='left'>Products</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shops
                  .map(shop => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={shop.id}
                      selected={selectedUsers.indexOf(shop.id) !== -1}
                      onClick={() => this.showShopDetails(shop)}
                    >
                      <TableCell className={classes.tableCell}>
                        <img className={classes.logo} src={getResizedImage(shop.image_url, 50, 50)} alt={shop.name} />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {shop.name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {shop.phone}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {this.getStatus(!!shop.address)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {shop.product_count || '0'}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Button className={classes.button} onClick={(e) => this.showDelete(e, shop)} > <DeleteIcon /> </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <DeleteShopConfirmation isOpen={this.state.showDelete} onSubmit={() => this.deleteShop(this.state.selectedShop.id)} shop={this.state.selectedShop} onCancel={this.hideDeleteShop} />
            {/* <DeleteConfirmation isOpen={showDelete} user={selectedUser} onCancel={this.hideDeleteProduct} onSubmit={this.confirmDelete} /> */}
          </PerfectScrollbar>
        </PortletContent>
      </Portlet>
    )
  }
}

ShopList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  shops: PropTypes.array.isRequired
}

ShopList.defaultProps = {
  shops: [],
  onSelect: () => { },
  onShowDetails: () => { }
}

function mapStateToProps (state) {
  return {
    delete: state.shops.delete
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(connect(mapStateToProps)(ShopList))
