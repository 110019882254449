import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Typography, withStyles } from '@material-ui/core'

import { Paper } from 'components'

// Component styles
import styles from './style'

class Shops extends Component {
  render () {
    const { classes, className, shop = {}, ...rest } = this.props

    const rootClassName = classNames(classes.root, className)
    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
              Shop Active
            </Typography>
            <Typography
              className={classes.value}
              variant='body2'
            >{shop.active}
            </Typography>
          </div>

          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
              Shop Inactive
            </Typography>
            <Typography
              className={classes.value}
              variant='body2'
            >{shop.not_active}
            </Typography>
          </div>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
              Shops Verified
            </Typography>
            <Typography
              className={classes.value}
              variant='body2'
            >{shop.verified}
            </Typography>
          </div>
        </div>
      </Paper>
    )
  }
}

Shops.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Shops)
