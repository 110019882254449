import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Grid, withStyles, CircularProgress, Typography } from '@material-ui/core'
import { Dashboard as DashboardLayout } from 'layouts'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
// import treeChanges from 'tree-changes'

import { STATUS } from 'redux/constants/index'

import { ShopList } from './components'
import SearchToolbar from './../../common/SearchToolbar'
import { getNewShops } from 'redux/actions'
import style from './style'
import treeChanges from 'tree-changes'

const itemsPerPage = 20

// Component styles

class NewShops extends Component {
  state = {
    searchString: '',
    page: 0
  }

  componentDidMount = () => {
    if (this.props.shops.status === STATUS.IDLE) {
      this.props.dispatch(getNewShops(0, itemsPerPage))
    } else {
      const searchString = this.props.shops.searchParams.searchString
      const page = this.props.shops.searchParams.skip ? this.props.shops.searchParams.skip / this.props.shops.searchParams.limit : 0
      this.setState({ page, searchString })
    }
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('shops.status', STATUS.READY)) {
      this.setState({ isLoading: false })
    }
  }

  onChangeSearchString = event => {
    const { value } = event.target
    this.setState({ searchString: value })
  }

  onClickSearch = () => {
    const { searchString } = this.state
    this.setState({ isLoading: true, page: 0 })
    this.props.dispatch(getNewShops(0, itemsPerPage, searchString))
  }

  refreshData = () => {
    const { isLoading, page, searchString } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    if (!isLoading) {
      this.setState({ isLoading: true }, () => {
        this.props.dispatch(getNewShops(0, nextIndex, searchString))
      })
    }
  }

  fetchNextPage = () => {
    const { shops } = this.props
    const { isLoading, page, searchString } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    if (!isLoading && !shops.reachedEnd) {
      this.setState({ page: page + 1, isLoading: true }, () => {
        this.props.dispatch(getNewShops(nextIndex, itemsPerPage, searchString))
      })
    }
  }

  // onSubmitPartner = phone => {
  //   this.setState({ showAddDeliveryPerson: false })
  //   this.props.dispatch(createPartner(phone))
  // }
  getShopsList = () => {
    const { shops, classes } = this.props
    if (shops.status === STATUS.RUNNING && shops.data.length === 0) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )
    }

    if (shops.data.length === 0) {
      return (
        <Typography variant='h6'>There are no new shops</Typography>
      )
    }
    return (
      <React.Fragment>
        {shops.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
          <CircularProgress />
        </div> : null}
        <ShopList shops={shops.data} deleteDeliveryPartner={this.deleteDeliveryPartner} fetchNextPage={this.fetchNextPage} />
      </React.Fragment>
    )
  }

  render () {
    const { searchString } = this.state
    const { classes } = this.props
    return (
      <DashboardLayout title='New Shops'>
        <div className={classes.root}>
          <Grid
            container
            className={classes.container}
            spacing={4}
          >
            <SearchToolbar searchString={searchString} onChangeSearchString={this.onChangeSearchString} onClickSearch={this.onClickSearch} onClickRefreshButton={this.refreshData} />
            {this.getShopsList()}
          </Grid>
        </div>
        {/* <AddPartner isOpen={showAddDeliveryPerson} onCancel={this.hideAddDeliveryPerson} onSubmit={this.onSubmitPartner} /> */}
      </DashboardLayout>
    )
  }
}

NewShops.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    shops: state.shops.newShops
  }
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(NewShops))
