import React, { Component } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import { Typography, withStyles, Switch, CircularProgress } from '@material-ui/core'
import { Portlet, PortletContent } from 'components'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import style from './style'
import { getResizedImage } from 'services/utility'
import treeChanges from 'tree-changes'
import { editShopLogo, showAlert } from 'redux/actions'
import { getCookie } from 'services/cookie'
import { APIEndpoints, STATUS } from 'redux/constants'

class ShopProfile extends Component {
  state = {
    isUploadingImage: false
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('editShopLogo.status', STATUS.ERROR)) {
      this.setState({ isUploadingImage: false })
      this.props.dispatch(showAlert(newProps.editShopLogo.message, { variant: 'error' }))
    } else if (changedTo('editShopLogo.status', STATUS.READY)) {
      this.setState({ isUploadingImage: false })
      this.props.dispatch(showAlert('Changed shop logo successfully', { variant: 'success' }))
    }
  }

  handleAcceptStatusChange = e => {
    const { checked } = e.target
    this.props.verifyShop(checked)
  }

  handleImageChange = async (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    let data = new FormData()
    data.append('image', file, file.name)
    const authToken = getCookie('auth_token_admin')
    const token = `Bearer ${authToken}`
    const shopId = this.props.shop.id
    const url = APIEndpoints.UPLOAD_SHOP_LOGO(shopId)
    const params = {
      method: 'POST',
      body: data,
      headers: {
        'Authorization': token
      }
    }
    this.setState({ isUploadingImage: true })
    fetch(url, params).then(async response => {
      if (response.status > 299) {
        this.props.dispatch(showAlert(' ImageUpload failed. Please try again', { variant: 'error' }))
        this.setState({ isUploadingImage: false })
      } else {
        const result = await response.json()
        const imageUrl = result.url
        this.props.dispatch(editShopLogo(shopId, imageUrl))
      }
    }, () => {
      this.setState({ isUploadingImage: false })
      this.props.dispatch(showAlert(' ImageUpload failed. Please try again', { variant: 'error' }))
    })
  }

  onClickFileUpload = event => {
    event.stopPropagation()
  }

  render () {
    const { classes, className, shop, deleteShop, ...rest } = this.props
    const { isUploadingImage } = this.state
    const rootClassName = classNames(classes.root, className)

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletContent>
          <div className={classes.details}>
            <div className={classes.info}>
              <Typography variant='h4'>{shop.name}</Typography>
              <Typography
                className={classes.locationText}
                variant='body1'
              >
                {shop.shop_type ? shop.shop_type.type : ''}
              </Typography>
              <Typography
                className={classes.dateText}
                variant='body1'
              >
                {shop.address ? (shop.address.landmark ? `${shop.address.landmark}, ${shop.address.locality}` : shop.address.locality) : ''}
              </Typography>
            </div>
            <div
              className={classes.avatar}
              style={{ backgroundImage: `url(${shop.image_url ? getResizedImage(shop.image_url, 200, 200) : 'images/avatar.png'})` }}
            >
              {isUploadingImage ? <div className={classes.loading} >
                <CircularProgress />
              </div> : null}
              <div className={classes.edit} >
                <input
                  accept='image/*'
                  className={classes.input}
                  style={{ display: 'none' }}
                  id='raised-button-file'
                  onClick={this.onClickFileUpload}
                  onChange={(e) => this.handleImageChange(e)}
                  type='file'
                />
                <label htmlFor='raised-button-file'>
                  <CameraAltIcon className={classes.editIcon} variant='outline' style={{ color: 'white' }} />
                </label>
              </div>
            </div>
          </div>
          <div className={classes.acceptStatus}>
            <Typography className={classes.statusTitle} variant='body1'>Shop Verified</Typography>
            <Switch
              className={classes.switch}
              checked={shop.verification_status}
              onChange={this.handleAcceptStatusChange}
              value='checkedA'
              // disabled={isLoading}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
        </PortletContent>
      </Portlet>
    )
  }
}

ShopProfile.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    editShopLogo: state.shops.editShopLogo
  }
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(ShopProfile))
