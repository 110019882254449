import auth from './auth'
import app from './app'
import shops from './shops'
import users from './users'
import analytics from './analytics'
import orders from './orders'
import products from './products'

export default {
  ...auth,
  ...app,
  ...shops,
  ...analytics,
  ...users,
  ...orders,
  ...products
}
