/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'

/**
 * Signup
 */
export function * getAnalytics ({ payload }) {
  try {
    const response = yield call(request, APIEndpoints.GET_ANALYTICS, {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_ANALYTICS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ANALYTICS_FAILURE,
      payload: err
    })
  }
}

export function * getSales ({ payload }) {
  try {
    const response = yield call(request, APIEndpoints.GET_SALES, {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_SALES_SUCCESS,
      payload: response.orders.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SALES_FAILURE,
      payload: err
    })
  }
}

export function * getDashboardStats ({ payload }) {
  try {
    const response = yield call(request, APIEndpoints.GET_DASHBOARD_STATS, {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_DASHBOARD_STATS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_DASHBOARD_STATS_FAILURE,
      payload: err
    })
  }
}
/**
 * User Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.GET_ANALYTICS, getAnalytics),
    takeLatest(ActionTypes.GET_SALES, getSales),
    takeLatest(ActionTypes.GET_DASHBOARD_STATS, getDashboardStats)
  ])
}
