import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Typography,
  CircularProgress,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  Button,
  Grid,
  TableContainer,
  Paper,
  Chip
} from '@material-ui/core'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import PrintIcon from '@material-ui/icons/Print'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
// import UpdateIcon from '@material-ui/icons/Update'
import compose from 'recompose/compose'
import moment from 'moment'
import Barcode from 'react-barcode'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { Dashboard as DashboardLayout } from 'layouts'

import treeChanges from 'tree-changes'
import { STATUS } from 'redux/constants'
import { Progress } from 'components'
import ConfirmCancelOrder from '../../common/ConfirmCanelOrder'
import { getOrderDetails, showAlert, cancelOrder } from 'redux/actions/index'

import style from './style'

const buttonText = {
  'confirmed': 'Confirm',
  'shop_confirmed': 'Set as Packed',
  'shop_packed': 'Delivery Ready',
  'shop_delivery': 'Mark as delivered'
}
class OrderDetails extends Component {
  state = {
    showConfirmation: false
  }

  componentDidMount () {
    this.setState({ isLoading: true })
    if (this.props.match.params.id) {
      this.props.dispatch(getOrderDetails(this.props.match.params.id))
    }
  }

  componentWillReceiveProps = newProps => {
    const { changed, changedTo } = treeChanges(this.props, newProps)
    if (changed('orderId') && newProps.orderId) {
      this.setState({ isLoading: true })
      this.props.dispatch(getOrderDetails(newProps.orderId))
    }
    if (changedTo('details.status', STATUS.READY)) {
      this.setState({ isLoading: false })
    }
    if (changedTo('cancelOrder.status', STATUS.READY)) {
      this.hideConfirmation()
      this.props.dispatch(showAlert('Successfully cancelled order', { variant: 'success' }))
      this.setState({ isLoading: false })
    }
  }

  hideConfirmation = () => {
    this.setState({ showConfirmation: false })
  }

  showCancelOrderConfirmation = () => {
    this.setState({ showConfirmation: true, type: 'cancel' })
  }

  cancelOrder = (message) => {
    const { id } = this.props.details.data
    this.props.dispatch(cancelOrder(id, message))
  }

  navigateToLocation = () => {
    const { details } = this.props
    const shopInfo = details.data.shop
    const address = details.data.delivery_address
    let shopDirection = `https://www.google.com/maps/dir/@${address.coordinates_full.lat},${address.coordinates_full.long},16z`
    if (shopInfo && shopInfo.address && shopInfo.address.coordinates) {
      const shopCoordinates = shopInfo.address.coordinates
      shopDirection = `https://www.google.com/maps/dir/${address.coordinates_full.lat},${address.coordinates_full.long}/${shopCoordinates.lat},${shopCoordinates.long}`
    }
    let anchor = document.createElement('a')
    anchor.href = shopDirection
    anchor.target = '_blank'
    document.body.appendChild(anchor)
    anchor.click()
  }

  copyLocationLink = () => {
    const { details } = this.props
    const shopInfo = details.data.shop
    const address = details.data.delivery_address
    let shopDirection = `https://www.google.com/maps/dir/@${address.coordinates_full.lat},${address.coordinates_full.long},16z`
    if (shopInfo && shopInfo.address && shopInfo.address.coordinates) {
      const shopCoordinates = shopInfo.address.coordinates
      shopDirection = `https://www.google.com/maps/dir/${address.coordinates_full.lat},${address.coordinates_full.long}/${shopCoordinates.lat},${shopCoordinates.long}`
    }
    if (navigator.clipboard) {
      navigator.clipboard.writeText(shopDirection).then(() => {
        this.props.dispatch(showAlert('Copied location url to clipboard!', { variant: 'success' }))
      }, () => {
        this.props.dispatch(showAlert('Failed to copy location url to clipboard!', { variant: 'error' }))
      })
    } else {
      let textField = document.createElement('textarea')
      textField.innerText = shopDirection
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
      document.execCommand('copy', false, shopDirection)
      this.props.dispatch(showAlert('Copied location url to clipboard!', { variant: 'success' }))
    }
  }

  openCustomerWhatsApp = () => {
    window.open('https://wa.me/' + this.props.details.data.user_details.phone, 'blank')
  }

  openSellerWhatsApp = () => {
    window.open('https://wa.me/+91' + this.props.details.data.shop.contact.customer_care, 'blank')
  }

  getPrintingDetails = () => {
    const { classes, details } = this.props
    return <div className={`bill ${classes.printBill}`} ref={el => (this.componentRef = el)}>
      <Typography className={classes.billSubHeadingText} variant='h4' >Order: {details.data.code}</Typography>
      <div className={classes.billHeading} >
        <div className={classes.billLeftHeading} >
          <Typography className={classes.billSubHeadingText} variant='h4' >{details.data.shop.name}</Typography>
          <Typography className={classes.billSubHeadingText} variant='p' >{details.data.shop.phone}</Typography>
        </div>
        <div className={classes.billRightHeading} >
          <Typography className={classes.billSubHeadingText} variant='h4' >{details.data.user_details.name}</Typography>
          <Typography className={classes.billSubHeadingText} variant='p' >{details.data.user_details.phone}</Typography>
          {details.data.user_details.member_id ? <Typography className={classes.billSubHeadingText} variant='p' >{details.data.user_details.member_id}</Typography> : null}
          {details.data.user_details.address.full_address ? <Typography className={classes.billSubHeadingText} variant='p' >{details.data.user_details.address.full_address}</Typography> : null}
          {details.data.user_details.address.landmark ? <Typography className={classes.billSubHeadingText} variant='p' >{details.data.user_details.address.landmark}</Typography> : null}
          {details.data.user_details.address.locality ? <Typography className={classes.billSubHeadingText} variant='p' >{details.data.user_details.address.locality}</Typography> : null}
        </div>
      </div>
      <div className={classes.billBody} >
        <div className={classes.billItemList} >
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Item Name</TableCell>
                  <TableCell align='right'>Item Code</TableCell>
                  <TableCell align='right'>Count</TableCell>
                  <TableCell align='right'>Price Per Item</TableCell>
                  <TableCell align='right'>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details.data && details.data.products && details.data.products.map(product => (
                  <TableRow
                    className={classes.tableRow}
                  >
                    <TableCell component='th' scope='row'>
                      {product.item.name}
                    </TableCell>
                    <TableCell align='right'>{product.item.item_code ? <Barcode value={product.item.item_code} /> : '-'}</TableCell>
                    <TableCell align='right'>{product.count}</TableCell>
                    <TableCell align='right'>{product.item.price}</TableCell>
                    <TableCell align='right'>{parseFloat(product.count * product.item.price).toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className={classes.billCharges} >
          <div className={classes.billSubDetailsContainer} >
            <div className={classes.billDetailsCon} >
              <Typography className={classes.billSubHeading} variant='p' >Total Items</Typography>
              <Typography className={classes.billSubitem} variant='p' >{details.data.bill_details.total_products}</Typography>
            </div>
            <div className={classes.billDetailsCon} >
              <Typography className={classes.billSubHeading} variant='p' >Cart Value</Typography>
              <Typography className={classes.billSubitem} variant='p' > {`\u20B9  ${parseFloat(details.data.bill_details.product_price).toFixed(2)}`}</Typography>
            </div>
            <div className={classes.billDetailsCon} >
              <Typography className={classes.billSubHeading} variant='p' >Delivery Charge</Typography>
              <Typography className={classes.billSubitem} variant='p' > {`\u20B9  ${parseFloat(details.data.bill_details.delivery_price).toFixed(2)}`}</Typography>
            </div>
            <div className={classes.billDetailsCon} >
              <Typography className={classes.billSubHeading} variant='p' >Total</Typography>
              <Typography className={classes.billSubitem} variant='p' > {`\u20B9  ${parseFloat(details.data.bill_details.total_price).toFixed(2)}`}</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  render () {
    const { classes, details } = this.props
    const { showConfirmation } = this.state

    const isValidStatus = buttonText[details.data.status]

    return (
      <DashboardLayout title='Order Details'>
        <div className={classes.root}>
          {details.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
            : <Grid container spacing={2} className={classes.container}>
              <Grid xs={12}>
                <div className={classes.action} >
                  <div className={classes.locationButtons} >
                    <Button color='primary' className={classes.locationButton} variant='contained' onClick={this.navigateToLocation} > View Location </Button>
                    <Button color='secondary' className={classes.locationButton} variant='contained' onClick={this.copyLocationLink} > Copy
                      <PersonPinCircleIcon className={classes.location} fontSize='large' />
                    </Button>
                  </div>
                  {/* { isValidStatus ? <Button color='primary' variant='outlined' onClick={this.showConfirmation} > {buttonText[details.data.status]} </Button> : null } */}
                  <div className={classes.iconButtons} >
                    <Chip avatar={<WhatsAppIcon />} onClick={this.openSellerWhatsApp} label='Seller' />
                    <Chip avatar={<WhatsAppIcon />} onClick={this.openCustomerWhatsApp} label='Customer' />
                    <ReactToPrint
                      trigger={() =>
                        <Tooltip title='Print Bill'>
                          <IconButton color='primary' aria-label='cancel' size='small'>
                            <PrintIcon className={classes.footerIcons} style={{ color: '#66788A' }} />
                          </IconButton>
                        </Tooltip>
                      }
                      content={() => this.componentRef}
                    />
                    {isValidStatus ? <Tooltip title='Cancel Order'>
                      <IconButton color='primary' onClick={this.showCancelOrderConfirmation} aria-label='cancel' size='small'>
                        <AssignmentLateIcon className={classes.footerIcons} style={{ color: '#66788A' }} />
                      </IconButton>
                    </Tooltip> : null}
                  </div>
                  {details.data && details.data.id ? this.getPrintingDetails() : null}
                </div>
              </Grid>
              <Grid xs={8} item>
                <Grid xs={12}>
                  <Progress status={details.data.status} />
                </Grid>
                <Grid xs={12} container spacing={2}>
                  <Grid xs={6} item>
                    <div className={classes.subDetailsContainer} >
                      {details.data && details.data.code ? <div className={classes.deliveryBy} >
                        <Typography className={classes.subHeading} variant='p' >Order Code</Typography>
                        <Typography className={classes.item} variant='p' > {details.data.code}</Typography>
                      </div> : null}
                      {details.data.delivery_details && details.data.delivery_details.delivery_by ? <div className={classes.deliveryBy} >
                        <Typography className={classes.subHeading} variant='p' >Expected Delivery</Typography>
                        <Typography className={[classes.item, classes.itemLight]} variant='p' > {moment(details.data.delivery_details.delivery_by).format('D MMM YYYY HH:mm')}</Typography>
                      </div> : null}
                      {details.data.delivery_details && details.data.confirmed_ts ? <div className={classes.deliveryBy} >
                        <Typography className={classes.subHeading} variant='p' >Ordered Time</Typography>
                        <Typography className={[classes.item, classes.itemLight]} variant='p' > {moment(details.data.confirmed_ts).format('D MMM YYYY HH:mm')}</Typography>
                      </div> : null}
                      {details.data.delivery_details && details.data.delivery_details.distance ? <div className={classes.deliveryDistance} >
                        <Typography className={classes.subHeading} variant='p' >Distance</Typography>
                        <Typography className={[classes.item, classes.itemLight]} variant='p' > {`${parseFloat(details.data.delivery_details.distance / 1000).toFixed(2)} Km`}</Typography>
                      </div> : null}
                    </div>
                    <div className={classes.subDetailsContainer} >
                      <Typography className={classes.billTitle} variant='p' >Bill Details</Typography>
                      <div className={classes.billDetails} >
                        <Typography className={classes.subHeading} variant='p' >Total Items</Typography>
                        <Typography className={[classes.item, classes.itemLight]} variant='p' >{details.data.bill_details && details.data.bill_details.total_products ? parseFloat(details.data.bill_details.total_products) : 0}</Typography>
                      </div>
                      <div className={classes.billDetails} >
                        <Typography className={classes.subHeading} variant='p' >Cart Value</Typography>
                        <Typography className={[classes.item, classes.itemLight]} variant='p' > {`\u20B9  ${details.data.bill_details && details.data.bill_details.product_price ? parseFloat(details.data.bill_details.product_price).toFixed(2) : 0}`}</Typography>
                      </div>
                      <div className={classes.billDetails} >
                        <Typography className={classes.subHeading} variant='p' >Delivery Charge</Typography>
                        <Typography className={[classes.item, classes.itemLight]} variant='p' > {`\u20B9  ${details.data.bill_details && details.data.bill_details.delivery_price ? parseFloat(details.data.bill_details.delivery_price).toFixed(2) : 0}`}</Typography>
                      </div>
                      <div className={classes.billDetails} >
                        <Typography className={classes.subHeading} variant='p' >Total</Typography>
                        <Typography className={classes.item} variant='p' > {`\u20B9  ${details.data.bill_details && details.data.bill_details.total_price ? parseFloat(details.data.bill_details.total_price).toFixed(2) : 0}`}</Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={6} item>
                    <div className={classes.subDetailsContainer} >
                      <Typography className={classes.customerHeading} variant='p' >Customer Details</Typography>
                      <div className={classes.customerDetails} >
                        <Typography className={classes.subCustomerDetails} variant='p' >{details.data.user_details && details.data.user_details.name}</Typography>
                        <Typography className={classes.subCustomerDetails} variant='p' >{details.data.user_details && details.data.user_details.phone}</Typography>
                        {details.data.user_details && details.data.user_details.member_id ? <Typography className={classes.subCustomerDetails} variant='p' >{details.data.user_details.member_id}</Typography> : null}
                        <div className={classes.address} >
                          <Typography className={classes.subAddress} variant='p' >{details.data.user_details && details.data.delivery_address.name}</Typography>landmark
                          <Typography className={classes.subAddress} variant='p' >{details.data.user_details && details.data.delivery_address.full_address}</Typography>
                          <Typography className={classes.subAddress} variant='p' >{details.data.user_details && details.data.delivery_address.landmark}</Typography>
                          <Typography className={classes.subAddress} variant='p' >{details.data.user_details && details.data.delivery_address.locality}</Typography>
                        </div>
                      </div>
                    </div>
                    <div className={classes.subDetailsContainer} >
                      <Typography className={classes.customerHeading} variant='p' >Shop Details</Typography>
                      <div className={classes.customerDetails} >
                        <Typography className={classes.subCustomerDetails} variant='p' >{details.data.shop && details.data.shop.name}</Typography>
                        <Typography className={classes.subCustomerDetails} variant='p' >{details.data.shop ? details.data.shop.contact.customer_care || details.data.shop.contact.phone : null}</Typography>
                        {details.data.shop && details.data.shop.address ? <div className={classes.address} >
                          <Typography className={classes.subAddress} variant='p' >{details.data.shop.address.address}</Typography>
                          <Typography className={classes.subAddress} variant='p' >{details.data.shop.address.locality}</Typography>
                        </div> : null}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={4} item>
                <div className={classes.bill} >
                  <Typography className={classes.productHeading} variant='p' > Products </Typography>
                  <div className={classes.products} >
                    {details.data && details.data.products && details.data.products.map(product => (
                      <div className={classes.productCell}>
                        <Typography className={classes.cellItem} variant='body1' > {product.item.name} </Typography>
                        <Typography className={classes.cellItem} variant='body1' > {`X ${product.count}`}</Typography>
                      </div>
                    ))
                    }
                  </div>
                  <div className={classes.productPrice}>
                    <Typography className={classes.subHeading} variant='body1' > Total Price </Typography>
                    <Typography className={classes.item} variant='body1' >{`\u20B9 ${details.data.bill_details && details.data.bill_details.total_price ? details.data.bill_details.total_price.toFixed(2) : 0}`}</Typography>
                  </div>
                </div>
              </Grid>
              <ConfirmCancelOrder isOpen={showConfirmation} onCancel={this.hideConfirmation} onSubmit={this.cancelOrder} />
            </Grid>
          }
        </div>
      </DashboardLayout>
    )
  }
}

OrderDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    details: state.orders.details,
    cancelOrder: state.orders.cancelOrder
  }
}

export default compose(
  withRouter,
  withStyles(style)
)(connect(mapStateToProps)(OrderDetails))
