import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

// Externals
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import validate from 'validate.js'
import _ from 'underscore'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'

import { STATUS } from 'redux/constants/index'
import { userLogin, refreshToken, showAlert } from 'redux/actions/index'

import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'

import styles from './style'

import schema from './schema'

class SignIn extends Component {
  state = {
    values: {
      email: '',
      password: ''
    },
    touched: {
      email: false,
      password: false
    },
    errors: {
      email: null,
      password: null
    },
    isValid: false,
    isLoading: false,
    submitError: null
  }

  componentWillReceiveProps = nextProps => {
    const { dispatch } = this.props
    const { changedTo } = treeChanges(this.props, nextProps)

    /* istanbul ignore else */
    if (changedTo('user.isAuthenticated', true)) {
      this.setState({ isLoading: false })
      this.interval = setInterval(() => dispatch(refreshToken()), 45 * 60 * 1000)
    } else if (changedTo('user.status', STATUS.ERROR)) {
      // Handle error
      this.props.dispatch(showAlert('Login credential does not exist', { variant: 'error' }))
      this.setState({ isLoading: false })
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  validateForm = _.debounce(() => {
    const { values } = this.state
    const newState = { ...this.state }
    const errors = validate(values, schema)
    newState.errors = errors || {}
    newState.isValid = !errors
    this.setState(newState)
  }, 30)

  handleFieldChange = (field, value) => {
    const newState = { ...this.state }

    newState.submitError = null
    newState.touched[field] = true
    newState.values[field] = value

    this.setState(newState, this.validateForm)
  }

  handleSignIn = async () => {
    try {
      const { values } = this.state
      this.setState({ isLoading: true })
      this.props.dispatch(userLogin(values.email, values.password))
    } catch (error) {
      this.setState({
        isLoading: false,
        serviceError: error
      })
    }
  }

  render () {
    const { classes } = this.props
    const {
      values,
      touched,
      errors,
      isValid,
      submitError,
      isLoading
    } = this.state

    const showEmailError = touched.email && errors.email
    const showPasswordError = touched.password && errors.password

    return (
      <div className={classes.root}>
        <Grid
          className={classes.grid}
          container
        >
          <Grid
            className={classes.quoteWrapper}
            item
            lg={5}
          >
            <div className={classes.quote}>
              <div className={classes.quoteInner} />
            </div>
          </Grid>
          <Grid
            className={classes.content}
            item
            lg={7}
            xs={12}
          >
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <form className={classes.form}>

                  <Typography
                    className={classes.title}
                    variant='h2'
                  >
                    Login
                  </Typography>
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      label='Email address'
                      name='email'
                      onChange={event =>
                        this.handleFieldChange('email', event.target.value)
                      }
                      type='text'
                      value={values.email}
                      variant='outlined'
                    />
                    {showEmailError && (
                      <Typography
                        className={classes.fieldError}
                        variant='body2'
                      >
                        {errors.email[0]}
                      </Typography>
                    )}
                    <TextField
                      className={classes.textField}
                      label='Password'
                      name='password'
                      onChange={event =>
                        this.handleFieldChange('password', event.target.value)
                      }
                      type='password'
                      value={values.password}
                      variant='outlined'
                    />
                    {showPasswordError && (
                      <Typography
                        className={classes.fieldError}
                        variant='body2'
                      >
                        {errors.password[0]}
                      </Typography>
                    )}
                  </div>
                  {submitError && (
                    <Typography
                      className={classes.submitError}
                      variant='body2'
                    >
                      {submitError}
                    </Typography>
                  )}
                  {isLoading ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                    <Button
                      className={classes.signInButton}
                      color='primary'
                      disabled={!isValid}
                      onClick={this.handleSignIn}
                      size='large'
                      variant='contained'
                    >
                      Sign in now
                    </Button>
                  )}

                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

SignIn.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

/* istanbul ignore next */
function mapStateToProps (state) {
  return {
    user: state.user
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(connect(mapStateToProps)(SignIn))
