// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const { getAnalytics, getSales, getDashboardStats } = createActions({
  [ActionTypes.GET_ANALYTICS]: () => ({ }),
  [ActionTypes.GET_SALES]: () => ({ }),
  [ActionTypes.GET_DASHBOARD_STATS]: () => ({ })
})
