import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  CircularProgress,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  Button
} from '@material-ui/core'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import PrintIcon from '@material-ui/icons/Print'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
// import UpdateIcon from '@material-ui/icons/Update'
import Modal from 'views/Modal'
import compose from 'recompose/compose'
import moment from 'moment'
import Barcode from 'react-barcode'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'

import treeChanges from 'tree-changes'
import { STATUS } from 'redux/constants'
import { Progress } from 'components'
import ConfirmCancelOrder from './../ConfirmCanelOrder'
import { getOrderDetails, showAlert, cancelOrder } from 'redux/actions/index'

import styles from './style'

const buttonText = {
  'confirmed': 'Confirm',
  'shop_confirmed': 'Set as Packed',
  'shop_packed': 'Delivery Ready',
  'shop_delivery': 'Mark as delivered'
}
class OrderDetails extends Component {
  state = {
    showConfirmation: false
  }

  componentDidMount () {
    this.setState({ isLoading: true })
    if (this.props.orderId) {
      this.props.dispatch(getOrderDetails(this.props.orderId))
    }
  }

  componentWillReceiveProps = newProps => {
    const { changed, changedTo } = treeChanges(this.props, newProps)
    if (changed('orderId') && newProps.orderId) {
      this.setState({ isLoading: true })
      this.props.dispatch(getOrderDetails(newProps.orderId))
    }
    if (changedTo('details.status', STATUS.READY)) {
      this.setState({ isLoading: false })
    }
    if (changedTo('cancelOrder.status', STATUS.READY)) {
      this.hideConfirmation()
      this.props.dispatch(showAlert('Successfully cancelled order', { variant: 'success' }))
      this.setState({ isLoading: false })
    }
  }

  // showConfirmation = () => {
  //   this.setState({ showConfirmation: true })
  // }

  hideConfirmation = () => {
    this.setState({ showConfirmation: false })
  }

  // showEditDeliveryTime = () => {
  //   this.setState({ showConfirmation: true, type: 'edit_delivery' })
  // }

  showCancelOrderConfirmation = () => {
    this.setState({ showConfirmation: true, type: 'cancel' })
  }

  // onSubmitConfirmation = (message, time, price) => {
  //   const { status, id } = this.props.details.data
  //   const { type } = this.state
  //   if (type) {
  //     if (type === 'edit_delivery') {
  //       this.updateDelivery(id, message, time)
  //     } else {
  //       this.cancelOrder(id, message)
  //     }
  //   } else if (status === 'confirmed') {
  //     this.confirmOrder(id, message, time, price)
  //   } else if (status === 'shop_confirmed') {
  //     this.confirmPacked(id, message)
  //   } else if (status === 'shop_packed') {
  //     this.confirmDelivery(id, message)
  //   } else if (status === 'shop_delivery') {
  //     this.confirmDelivered(id, message)
  //   }
  //   this.setState({ showConfirmation: false })
  // }

  // confirmOrder = (id, message, time, price) => {
  //   this.props.dispatch(confirmOrder(id, message, time, price))
  // }

  // confirmPacked = (id, message) => {
  //   this.props.dispatch(confirmPacked(id, message))
  // }

  // confirmDelivery = (id, message) => {
  //   this.props.dispatch(confirmDelivery(id, message))
  // }

  // confirmDelivered = (id, message) => {
  //   this.props.dispatch(confirmDelivered(id, message))
  // }

  // updateDelivery = (id, message, time) => {
  //   this.props.dispatch(updateDelivery(id, message, time))
  // }

  cancelOrder = (message) => {
    const { id } = this.props.details.data
    this.props.dispatch(cancelOrder(id, message))
  }

  openWhatsApp = () => {
    window.open('https://wa.me/' + this.props.details.data.user_details.phone, 'blank')
  }

  navigateToLocation = () => {
    const { details } = this.props
    const shopInfo = details.data.shop
    const address = details.data.delivery_address
    let shopDirection = `https://www.google.com/maps/dir/@${address.coordinates_full.lat},${address.coordinates_full.long},16z`
    if (shopInfo && shopInfo.address && shopInfo.address.coordinates) {
      const shopCoordinates = shopInfo.address.coordinates
      shopDirection = `https://www.google.com/maps/dir/${address.coordinates_full.lat},${address.coordinates_full.long}/${shopCoordinates.lat},${shopCoordinates.long}`
    }
    let anchor = document.createElement('a')
    anchor.href = shopDirection
    anchor.target = '_blank'
    document.body.appendChild(anchor)
    anchor.click()
  }

  copyLocationLink = () => {
    const { details } = this.props
    const shopInfo = details.data.shop
    const address = details.data.delivery_address
    let shopDirection = `https://www.google.com/maps/dir/@${address.coordinates_full.lat},${address.coordinates_full.long},16z`
    if (shopInfo && shopInfo.address && shopInfo.address.coordinates) {
      const shopCoordinates = shopInfo.address.coordinates
      shopDirection = `https://www.google.com/maps/dir/${address.coordinates_full.lat},${address.coordinates_full.long}/${shopCoordinates.lat},${shopCoordinates.long}`
    }
    if (navigator.clipboard) {
      navigator.clipboard.writeText(shopDirection).then(() => {
        this.props.dispatch(showAlert('Copied location url to clipboard!', { variant: 'success' }))
      }, () => {
        this.props.dispatch(showAlert('Failed to copy location url to clipboard!', { variant: 'error' }))
      })
    } else {
      let textField = document.createElement('textarea')
      textField.innerText = shopDirection
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
      document.execCommand('copy', false, shopDirection)
      this.props.dispatch(showAlert('Copied location url to clipboard!', { variant: 'success' }))
    }
  }

  getPrintingDetails = () => {
    const { classes, details } = this.props
    return <div className={`bill ${classes.printBill}`} ref={el => (this.componentRef = el)}>
      <Typography className={classes.billSubHeadingText} variant='h4' >Order: {details.data.code}</Typography>
      <div className={classes.billHeading} >
        <div className={classes.billLeftHeading} >
          <Typography className={classes.billSubHeadingText} variant='h4' >{details.data.shop.name}</Typography>
          <Typography className={classes.billSubHeadingText} variant='p' >{details.data.shop.phone}</Typography>
        </div>
        <div className={classes.billRightHeading} >
          <Typography className={classes.billSubHeadingText} variant='h4' >{details.data.user_details.name}</Typography>
          <Typography className={classes.billSubHeadingText} variant='p' >{details.data.user_details.phone}</Typography>
          {details.data.user_details.member_id ? <Typography className={classes.billSubHeadingText} variant='p' >{details.data.user_details.member_id}</Typography> : null}
          {details.data.user_details.address.full_address ? <Typography className={classes.billSubHeadingText} variant='p' >{details.data.user_details.address.full_address}</Typography> : null}
          {details.data.user_details.address.landmark ? <Typography className={classes.billSubHeadingText} variant='p' >{details.data.user_details.address.landmark}</Typography> : null}
          {details.data.user_details.address.locality ? <Typography className={classes.billSubHeadingText} variant='p' >{details.data.user_details.address.locality}</Typography> : null}

        </div>
      </div>
      <div className={classes.billBody} >
        <div className={classes.billItemList} >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell} align='left'>Item Name</TableCell>
                <TableCell align='left'>Item Code</TableCell>
                <TableCell align='left'>Count</TableCell>
                <TableCell align='left'>Price Per Item</TableCell>
                <TableCell align='left'>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {details.data && details.data.products && details.data.products.map(product => (
                <TableRow
                  className={classes.tableRow}
                >
                  <TableCell className={classes.tableCell}>{product.item.name}</TableCell>
                  <TableCell className={classes.tableCell}>{product.item.item_code ? <Barcode value={product.item.item_code} /> : '-'}</TableCell>
                  <TableCell className={classes.tableCell}>{product.count}</TableCell>
                  <TableCell className={classes.tableCell}>{product.item.price}</TableCell>
                  <TableCell className={classes.tableCell}>{parseFloat(product.count * product.item.price).toFixed(2)}</TableCell>
                </TableRow>
              ))
              }
            </TableBody>
          </Table>
        </div>
        <div className={classes.billCharges} >
          <div className={classes.billSubDetailsContainer} >
            <div className={classes.billDetailsCon} >
              <Typography className={classes.billSubHeading} variant='p' >Total Items</Typography>
              <Typography className={classes.billSubitem} variant='p' >{details.data.bill_details.total_products}</Typography>
            </div>
            <div className={classes.billDetailsCon} >
              <Typography className={classes.billSubHeading} variant='p' >Cart Value</Typography>
              <Typography className={classes.billSubitem} variant='p' > {`\u20B9  ${parseFloat(details.data.bill_details.product_price).toFixed(2)}`}</Typography>
            </div>
            <div className={classes.billDetailsCon} >
              <Typography className={classes.billSubHeading} variant='p' >Delivery Charge</Typography>
              <Typography className={classes.billSubitem} variant='p' > {`\u20B9  ${parseFloat(details.data.bill_details.delivery_price).toFixed(2)}`}</Typography>
            </div>
            <div className={classes.billDetailsCon} >
              <Typography className={classes.billSubHeading} variant='p' >Total</Typography>
              <Typography className={classes.billSubitem} variant='p' > {`\u20B9  ${parseFloat(details.data.bill_details.total_price).toFixed(2)}`}</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  render () {
    const { classes, onSubmit, isOpen, details } = this.props
    const { showConfirmation } = this.state

    const child = <div className={classes.container}>
      {details.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
        <CircularProgress />
      </div> : null}
      {details.status === STATUS.READY ? <React.Fragment >
        <div className={classes.mainContainer} >
          <Progress status={details.data.status} />
          <div className={classes.detailsContainer} >
            <div className={classes.left}>
              <div className={classes.subDetailsContainer} >
                {details.data && details.data.code ? <div className={classes.deliveryBy} >
                  <Typography className={classes.subHeading} variant='p' >Order Code</Typography>
                  <Typography className={classes.item} variant='p' > {details.data.code}</Typography>
                </div> : null}
                {details.data.delivery_details && details.data.delivery_details.delivery_by ? <div className={classes.deliveryBy} >
                  <Typography className={classes.subHeading} variant='p' >Expected Delivery</Typography>
                  <Typography className={classes.item} variant='p' > {moment(details.data.delivery_details.delivery_by).format('D MMM YYYY HH:mm')}</Typography>
                </div> : null}
                {details.data.delivery_details && details.data.confirmed_ts ? <div className={classes.deliveryBy} >
                  <Typography className={classes.subHeading} variant='p' >Ordered Time</Typography>
                  <Typography className={classes.item} variant='p' > {moment(details.data.confirmed_ts).format('D MMM YYYY HH:mm')}</Typography>
                </div> : null}
                {details.data.delivery_details && details.data.delivery_details.distance ? <div className={classes.deliveryDistance} >
                  <Typography className={classes.subHeading} variant='p' >Distance</Typography>
                  <Typography className={classes.item} variant='p' > {`${parseFloat(details.data.delivery_details.distance / 1000).toFixed(2)} Km`}</Typography>
                </div> : null}
              </div>
              <div className={classes.subDetailsContainer} >
                <Typography className={classes.billTitle} variant='p' >Bill Details</Typography>
                <div className={classes.billDetails} >
                  <Typography className={classes.subHeading} variant='p' >Total Items</Typography>
                  <Typography className={classes.item} variant='p' >{parseFloat(details.data.bill_details.total_products)}</Typography>
                </div>
                <div className={classes.billDetails} >
                  <Typography className={classes.subHeading} variant='p' >Cart Value</Typography>
                  <Typography className={classes.item} variant='p' > {`\u20B9  ${details.data.bill_details.product_price ? parseFloat(details.data.bill_details.product_price).toFixed(2) : 0}`}</Typography>
                </div>
                <div className={classes.billDetails} >
                  <Typography className={classes.subHeading} variant='p' >Delivery Charge</Typography>
                  <Typography className={classes.item} variant='p' > {`\u20B9  ${details.data.bill_details.delivery_price ? parseFloat(details.data.bill_details.delivery_price).toFixed(2) : 0}`}</Typography>
                </div>
                <div className={classes.billDetails} >
                  <Typography className={classes.subHeading} variant='p' >Total</Typography>
                  <Typography className={classes.item} variant='p' > {`\u20B9  ${details.data.bill_details.total_price ? parseFloat(details.data.bill_details.total_price).toFixed(2) : 0}`}</Typography>
                </div>
              </div>
              {details.data.delivery_agent && details.data.delivery_agent.delivery_agent_name ? (
                <div className={classes.subDetailsContainer} >
                  <Typography className={classes.billTitle} variant='p' >Delivery Agent Details</Typography>
                  <div className={classes.billDetails} >
                    <Typography className={classes.subHeading} variant='p' >Name</Typography>
                    <Typography className={classes.item} variant='p' >{details.data.delivery_agent.delivery_agent_name}</Typography>
                  </div>
                  <div className={classes.billDetails} >
                    <Typography className={classes.subHeading} variant='p' >Phone</Typography>
                    <Typography className={classes.item} variant='p' > {details.data.delivery_agent.delivery_agent_phone}</Typography>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={classes.right}>
              <div className={classes.subDetailsContainer} >
                <Typography className={classes.customerHeading} variant='p' >Customer Details</Typography>
                <div className={classes.customerDetails} >
                  <Typography className={classes.subCustomerDetails} variant='p' >{details.data.user_details.name}</Typography>
                  <Typography className={classes.subCustomerDetails} variant='p' >{details.data.user_details.phone}</Typography>
                  {details.data.user_details.member_id ? <Typography className={classes.subCustomerDetails} variant='p' >{details.data.user_details.member_id}</Typography> : null}
                  <div className={classes.address} >
                    <Typography className={classes.subAddress} variant='p' >{details.data.delivery_address.name}</Typography>landmark
                    <Typography className={classes.subAddress} variant='p' >{details.data.delivery_address.full_address}</Typography>
                    <Typography className={classes.subAddress} variant='p' >{details.data.delivery_address.landmark}</Typography>
                    <Typography className={classes.subAddress} variant='p' >{details.data.delivery_address.locality}</Typography>
                  </div>
                </div>
              </div>
              <div className={classes.locationButtons} >
                <Button color='primary' className={classes.locationButton} variant='contained' onClick={this.navigateToLocation} > View Location </Button>
                <Button color='secondary' className={classes.locationButton} variant='contained' onClick={this.copyLocationLink} > Copy
                  <PersonPinCircleIcon className={classes.location} fontSize='large' />
                </Button>
              </div>
              <div className={classes.subDetailsContainer} >
                <Typography className={classes.customerHeading} variant='p' >Shop Details</Typography>
                <div className={classes.customerDetails} >
                  <Typography className={classes.subCustomerDetails} variant='p' >{details.data.shop.name}</Typography>
                  <Typography className={classes.subCustomerDetails} variant='p' >{details.data.shop.contact.customer_care || details.data.shop.contact.phone}</Typography>
                  {details.data.shop.address ? <div className={classes.address} >
                    <Typography className={classes.subAddress} variant='p' >{details.data.shop.address.address}</Typography>
                    <Typography className={classes.subAddress} variant='p' >{details.data.shop.address.locality}</Typography>
                  </div> : null}
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className={classes.bill} >
          <Typography className={classes.productHeading} variant='p' > Products </Typography>
          <div className={classes.products} >
            {details.data && details.data.products && details.data.products.map(product => (
              <div className={classes.productCell}>
                <Typography className={classes.cellItem} variant='body1' > {product.item.name} </Typography>
                <Typography className={classes.cellItem} variant='body1' > {`X ${product.count}`}</Typography>
              </div>
            ))
            }
          </div>
          <div className={classes.productPrice}>
            <Typography className={classes.subHeading} variant='body1' > Total Price </Typography>
            <Typography className={classes.item} variant='body1' >{`\u20B9 ${details.data.bill_details.total_price ? details.data.bill_details.total_price.toFixed(2) : 0}`}</Typography>
          </div>
        </div>

      </React.Fragment> : null}
      <ConfirmCancelOrder isOpen={showConfirmation} onCancel={this.hideConfirmation} onSubmit={this.cancelOrder} />
    </div>
    const isValidStatus = buttonText[details.data.status]
    const footer = <div className={classes.action} >
      {/* { isValidStatus ? <Button color='primary' variant='outlined' onClick={this.showConfirmation} > {buttonText[details.data.status]} </Button> : null } */}
      <div className={classes.iconButtons} >
        <IconButton color='primary' onClick={this.openWhatsApp} aria-label='cancel' size='large'>
          <WhatsAppIcon />
        </IconButton>
        <ReactToPrint
          trigger={() =>
            <Tooltip title='Print Bill'>
              <IconButton color='primary' aria-label='cancel' size='small'>
                <PrintIcon className={classes.footerIcons} style={{ color: '#66788A' }} />
              </IconButton>
            </Tooltip>
          }
          content={() => this.componentRef}
        />
        {/* { isValidStatus ? <Tooltip title='Edit Delivery Time'>
          <IconButton color='primary' onClick={this.showEditDeliveryTime} aria-label='edit' size='small'>
            <UpdateIcon className={classes.footerIcons} style={{ color: '#66788A' }} />
          </IconButton>
        </Tooltip> : null } */}
        {isValidStatus ? <Tooltip title='Cancel Order'>
          <IconButton color='primary' onClick={this.showCancelOrderConfirmation} aria-label='cancel' size='small'>
            <AssignmentLateIcon className={classes.footerIcons} style={{ color: '#66788A' }} />
          </IconButton>
        </Tooltip> : null}
        <Button color='primary' className={classes.closeButton} variant='contained' onClick={onSubmit} > Close </Button>
      </div>
      {details.data && details.data.id ? this.getPrintingDetails() : null}
    </div>
    return (
      <Modal isOpen={isOpen} hideCancel width={1000} title={`Order Details`} okText={'Done'} footer={footer} onSubmit={onSubmit} child={child} />
    )
  }
}

OrderDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    details: state.orders.details,
    cancelOrder: state.orders.cancelOrder
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(OrderDetails))
