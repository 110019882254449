export default theme => ({
  root: {
    padding: 20,
    paddingBottom: 0,
    width: '100%'
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    margin: 0
  },
  tableContainer: {
    overflow: 'auto',
    height: `calc( 100vh - 157px )`
  },
  progressWrapper: {
    zIndex: 10,
    width: '100%',
    position: 'absolute',
    top: '500',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center'
  },
  tableRow: {
    height: '64px'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  },
  shopName: {
    whiteSpace: 'break-spaces !important'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  nameText: {
    display: 'inline-block',
    marginLeft: theme.spacing.unit * 2,
    fontWeight: 500,
    cursor: 'pointer'
  },
  deletebutton: {
    marginLeft: 20
  },
  error: {
    width: '100%',
    textAlign: 'center',
    marginTop: 20
  },
  button: {
    marginRight: 20,
    cursor: 'pointer'
  },
  actions: {
    display: 'flex',
    alignItems: 'center'
  }
})
