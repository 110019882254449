export default theme => ({
  root: {
    marginTop: 20,
    width: '100%'
  },
  container: {
    height: 'calc( 100vh - 135px )',
    width: '100%',
    overflow: 'auto'
  },
  tableRow: {
    height: '64px'
  },
  logo: {
    height: 50,
    maxWidth: 80
  },
  tableCell: {
    whiteSpace: 'nowrap',
    padding: '10px 5px 10px 10px',
    '&:first-child': {
      padding: '4px 14px'
    },
    '& button:first-child': {
      marginRight: 20
    }
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  nameText: {
    display: 'inline-block',
    marginLeft: theme.spacing.unit * 2,
    fontWeight: 500,
    cursor: 'pointer'
  },
  deletebutton: {
    marginLeft: 20
  },
  error: {
    width: '100%',
    textAlign: 'center',
    marginTop: 20
  },
  progressWrapper: {
    zIndex: 10,
    width: '100%',
    position: 'absolute',
    top: '500',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center'
  }
})
