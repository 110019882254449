import React, { Component } from 'react'
import LaunchIcon from '@material-ui/icons/Launch'
import VisibilityIcon from '@material-ui/icons/Visibility'
// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { Dashboard as DashboardLayout } from 'layouts'
import { withRouter } from 'react-router-dom'
import treeChanges from 'tree-changes'
import moment from 'moment'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  Grid,
  CircularProgress
} from '@material-ui/core'
import { Portlet, PortletContent } from 'components'
import styles from './style'
import { getActiveOrders } from 'redux/actions'
import { STATUS } from 'redux/constants'
import SearchToolbar from './../../common/SearchToolbar'
import OrderDetails from 'common/OrderDetails'

const itemsPerPage = 20

const statusMap = {
  confirmed: 'Pending',
  shop_confirmed: 'Confirmed',
  shop_packed: 'Packed',
  shop_delivery: 'Out for Delivery',
  delivered: 'Delivered',
  shop_cancelled: 'Shop Cancelled',
  customer_cancelled: 'Customer Cancelled'
}

class ActiveOrderList extends Component {
  state = {
    page: 0
  }

  componentDidMount = () => {
    if (this.props.orders.status === STATUS.IDLE) {
      this.props.dispatch(getActiveOrders(0, itemsPerPage))
    } else {
      const searchString = this.props.orders.searchParams.searchString
      const page = this.props.orders.searchParams.skip ? this.props.orders.searchParams.skip / this.props.orders.searchParams.limit : 0
      this.setState({ page, searchString })
    }
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('orders.status', STATUS.READY)) {
      this.setState({ isLoading: false })
    }
  }

  onChangeSearchString = event => {
    const { value } = event.target
    this.setState({ searchString: value })
  }

  onClickSearch = () => {
    const { searchString } = this.state
    this.setState({ isLoading: true, page: 0 })
    this.props.dispatch(getActiveOrders(0, itemsPerPage, searchString))
  }

  onReachedBottom = () => {
    const { orders } = this.props
    const { isLoading, page } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    const query = new URLSearchParams(this.props.location.search)
    const startingDate = query.get('starting')
    const endingDate = query.get('ending')
    if (!isLoading && !orders.reachedEnd) {
      this.setState({ page: page + 1, isLoading: true }, () => {
        if (startingDate && endingDate) {
          this.props.dispatch(getActiveOrders(nextIndex, itemsPerPage, '', startingDate, endingDate))
        } else {
          this.props.dispatch(getActiveOrders(nextIndex, itemsPerPage))
        }
      })
    }
  }

  refreshData = () => {
    const { isLoading, page } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    if (!isLoading) {
      this.setState({ isLoading: true }, () => {
        this.props.dispatch(getActiveOrders(0, nextIndex))
      })
    }
  }

  filterData = (startingDate, endingDate) => {
    if (startingDate && endingDate) {
      this.props.dispatch(getActiveOrders(0, itemsPerPage, '', startingDate, endingDate))
    }
  }

  showShopDetails = (shop, e) => {
    e.preventDefault()
    e.stopPropagation()
    let anchor = document.createElement('a')
    const baseUrl = window.location.origin
    const shopUrl = `${baseUrl}/shops/${shop.id}`
    anchor.href = shopUrl
    anchor.target = '_blank'
    document.body.appendChild(anchor)
    anchor.click()
  }

  onSelectOrder = id => {
    this.props.history.push('/order/' + id)
  }

  showOrderDetails = (id, e) => {
    e.preventDefault()
    e.stopPropagation()
    let anchor = document.createElement('a')
    const baseUrl = window.location.origin
    const shopUrl = `${baseUrl}/order/${id}`
    anchor.href = shopUrl
    anchor.target = '_blank'
    document.body.appendChild(anchor)
    anchor.click()
  }

  hideDetails = () => {
    this.setState({ selectedOrder: null, showDetails: false })
  }

  getOrderList () {
    const { classes, className, orders } = this.props

    const rootClassName = classNames(classes.tableRoot, className)
    if (orders.status === STATUS.RUNNING && orders.data.length === 0) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )
    }

    if (orders.data.length === 0) {
      return (
        <Typography variant='h6'>No Completed Orders Found</Typography>
      )
    }
    return (
      <Portlet className={rootClassName}>
        {orders.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
          <CircularProgress />
        </div> : null}
        <PortletContent className={classes.tableContainer} noPadding>
          {orders.data.length ? <PerfectScrollbar onYReachEnd={this.onReachedBottom}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Code</TableCell>
                  <TableCell align='left'>Items Count</TableCell>
                  <TableCell align='left'>Total</TableCell>
                  <TableCell align='left'>Shop</TableCell>
                  <TableCell align='left'>Order Time</TableCell>
                  <TableCell align='left'>Status</TableCell>
                  <TableCell align='left' />
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.data
                  .map(order => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={order.id}
                      onClick={() => this.onSelectOrder(order.id)}
                    >
                      <TableCell className={classes.tableCell}>
                        {order.code}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {order.bill.total_products}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {parseFloat(order.bill.total_price).toFixed(2)}
                      </TableCell>
                      <TableCell className={[classes.tableCell, classes.shopName]}>
                        {order.shop.name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {moment(order.timestamp).format('YYYY-MM-D HH:mm:ss')}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {statusMap[order.status]}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div className={classes.actions}>
                          <VisibilityIcon className={classes.button} onClick={(e) => this.showShopDetails(order.shop, e)} />
                          <LaunchIcon className={classes.button} onClick={(e) => this.showOrderDetails(order.id, e)} />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}

              </TableBody>
            </Table>
          </PerfectScrollbar> : null}
          {!orders.data.length && orders.status === STATUS.READY
            ? <Typography
              className={classes.error}
              hover
            >No Completed Orders Found</Typography> : null}
        </PortletContent>
      </Portlet>
    )
  }

  render () {
    const { searchString, showDetails, selectedOrder } = this.state
    const { classes } = this.props
    return (
      <DashboardLayout title='Active Orders'>
        <div className={classes.root}>
          <Grid
            container
            className={classes.container}
            spacing={4}
          >
            <SearchToolbar searchString={searchString} onChangeSearchString={this.onChangeSearchString} onClickSearch={this.onClickSearch} onClickRefreshButton={this.refreshData} onClickFilter={(startingDate, endingDate) => this.filterData(startingDate, endingDate)} />
            {this.getOrderList()}
          </Grid>
        </div>
        <OrderDetails isOpen={showDetails} orderId={selectedOrder} onSubmit={this.hideDetails} />
        {/* <AddPartner isOpen={showAddDeliveryPerson} onCancel={this.hideAddDeliveryPerson} onSubmit={this.onSubmitPartner} /> */}
      </DashboardLayout>
    )
  }
}

ActiveOrderList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  users: PropTypes.array.isRequired
}

ActiveOrderList.defaultProps = {
  orders: [],
  onSelect: () => { },
  onShowDetails: () => { }
}

function mapStateToProps (state) {
  return {
    orders: state.orders.activeOrders
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(connect(mapStateToProps)(ActiveOrderList))
