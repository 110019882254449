import React, { Component, Fragment } from 'react'

// Externals
import classNames from 'classnames'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'

import { withStyles, withWidth, Drawer } from '@material-ui/core'
import { Sidebar, Topbar } from './components'

import styles from './styles'

class Dashboard extends Component {
  constructor (props) {
    super(props)

    const isMobile = ['xs', 'sm'].includes(props.width)

    this.state = {
      isOpen: !isMobile
    }
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  };

  handleToggleOpen = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }))
  };

  render () {
    const { classes, width, title, children } = this.props
    const { isOpen } = this.state

    const isMobile = ['xs', 'sm'].includes(width)
    const shiftTopbar = isOpen && !isMobile
    const shiftContent = isOpen && !isMobile

    return (
      <Fragment>
        <Topbar
          className={classNames(classes.topbar, {
            [classes.topbarShift]: shiftTopbar
          })}
          isSidebarOpen={isOpen}
          onToggleSidebar={this.handleToggleOpen}
          title={title}
        />
        <Drawer
          anchor='left'
          classes={{ paper: classes.drawerPaper }}
          onClose={this.handleClose}
          open={isOpen}
          variant={isMobile ? 'temporary' : 'persistent'}
        >
          <Sidebar className={classes.sidebar} />
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: shiftContent
          })}
        >
          {children}
          {/* <Footer /> */}
        </main>
      </Fragment>
    )
  }
}

Dashboard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  width: PropTypes.string.isRequired
}

export default compose(
  withStyles(styles),
  withWidth()
)(Dashboard)
