import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core'
import { Portlet, PortletContent } from 'components'
import styles from './style'
import { getResizedImage } from 'services/utility'
// import { DeleteConfirmation } from '..'

class ShopList extends Component {
  state = {
    selectedUsers: [],
    rowsPerPage: 10,
    page: 0
  };

  handleSelectAll = event => {
    const { users, onSelect } = this.props

    let selectedUsers

    if (event.target.checked) {
      selectedUsers = users.map(user => user.id)
    } else {
      selectedUsers = []
    }

    this.setState({ selectedUsers })

    onSelect(selectedUsers)
  };

  handleSelectOne = (event, id) => {
    const { onSelect } = this.props
    const { selectedUsers } = this.state

    const selectedIndex = selectedUsers.indexOf(id)
    let newSelectedUsers = []

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id)
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1))
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      )
    }

    this.setState({ selectedUsers: newSelectedUsers })

    onSelect(newSelectedUsers)
  };

  handleChangePage = (event, page) => {
    this.setState({ page })
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  showShopDetails = (shop) => {
    const { history } = this.props
    history.push(`/shops/${shop.id}`)
  }

  onReachedBottom = () => {
    this.props.fetchNextPage()
  }

  getStatus = status => {
    const { classes } = this.props
    return <img className={classes.statusIcon} src={status ? 'images/check.png' : 'images/cross.png'} alt='check-status' />
  }

  render () {
    const { classes, className, shops } = this.props
    const { selectedUsers } = this.state

    const rootClassName = classNames(classes.root, className)

    return (
      <Portlet className={rootClassName}>
        <PortletContent className={classes.container} noPadding>
          <PerfectScrollbar onYReachEnd={this.onReachedBottom}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.heading} align='left'>Logo</TableCell>
                  <TableCell className={classes.heading} align='left'>Name</TableCell>
                  <TableCell className={classes.heading} align='left'>Phone</TableCell>
                  <TableCell className={classes.heading} align='left'>Online</TableCell>
                  <TableCell className={classes.heading} align='left'>Active</TableCell>
                  <TableCell className={classes.heading} align='left'>Products</TableCell>
                  <TableCell className={classes.heading} align='left'>Open Orders</TableCell>
                  <TableCell className={classes.heading} align='left'>Orders</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shops
                  .map(shop => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={shop.id}
                      selected={selectedUsers.indexOf(shop.id) !== -1}
                      onClick={() => this.showShopDetails(shop)}
                    >
                      <TableCell className={classes.tableCell}>
                        <img className={classes.logo} src={getResizedImage(shop.image_url, 50, 50)} alt={shop.name} />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <a className={classes.shopLink} href={`/shops/${shop.id}`}>
                          {shop.name}
                        </a>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {shop.phone}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {this.getStatus(shop.is_accepting_orders)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {this.getStatus(shop.is_active)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {shop.product_count || '0'}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {shop.active_order_count || '0'}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {shop.completed_order_count || '0'}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {/* <DeleteConfirmation isOpen={showDelete} user={selectedUser} onCancel={this.hideDeleteProduct} onSubmit={this.confirmDelete} /> */}
          </PerfectScrollbar>
        </PortletContent>
      </Portlet>
    )
  }
}

ShopList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  shops: PropTypes.array.isRequired
}

ShopList.defaultProps = {
  shops: [],
  onSelect: () => { },
  onShowDetails: () => { }
}
export default compose(
  withRouter,
  withStyles(styles)
)(ShopList)
