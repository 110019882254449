export default theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  info: {
    overflow: 'hidden',
    '& > h4': {
      maxWidth: '100%',
      wordWrap: 'break-word'
    }
  },
  locationText: {
    marginTop: theme.spacing.unit,
    color: theme.palette.text.secondary
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    background: '#8080809e'
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20
  },
  button: {
    marginBottom: 20
  }

})
