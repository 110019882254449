/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'

/**
 * Signup
 */
export function * getActiveOrders ({ payload }) {
  try {
    const { searchString, skip, limit, startingDate, endingDate } = payload
    const response = yield call(request, APIEndpoints.GET_ACTIVE_ORDERS(skip, limit, searchString, startingDate, endingDate), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_ACTIVE_ORDERS_SUCCESS,
      payload: response.orders
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ACTIVE_ORDERS_FAILURE,
      payload: err
    })
  }
}

export function * getCompletedOrders ({ payload }) {
  try {
    const { searchString, skip, limit, startingDate, endingDate } = payload
    const response = yield call(request, APIEndpoints.GET_COMPLETED_ORDERS(skip, limit, searchString, startingDate, endingDate), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_COMPLETED_ORDERS_SUCCESS,
      payload: response.orders
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_COMPLETED_ORDERS_FAILURE,
      payload: err
    })
  }
}

export function * getOrderDetails ({ payload }) {
  try {
    const { id } = payload
    const response = yield call(request, APIEndpoints.GET_ORDER_DETAILS(id), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_ORDER_DETAILS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ORDER_DETAILS_FAILURE,
      payload: err
    })
  }
}

export function * cancelOrder ({ payload }) {
  try {
    const { id, message } = payload
    yield call(request, APIEndpoints.CANCEL_ORDER(id), {
      method: 'POST',
      payload: { message }
    })
    yield put({
      type: ActionTypes.CANCEL_ORDER_SUCCESS,
      payload: { id }
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CANCEL_ORDER_FAILURE,
      payload: err
    })
  }
}
/**
 * User Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.GET_ACTIVE_ORDERS, getActiveOrders),
    takeLatest(ActionTypes.GET_COMPLETED_ORDERS, getCompletedOrders),
    takeLatest(ActionTypes.GET_ORDER_DETAILS, getOrderDetails),
    takeLatest(ActionTypes.CANCEL_ORDER, cancelOrder)

  ])
}
