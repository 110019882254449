// @flow
/**
 * @module Actions/App
 * @desc App Actions
 */

import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const { hideAlert, showAlert } = createActions({
  [ActionTypes.HIDE_ALERT]: (id) => ({ id }),
  [ActionTypes.SHOW_ALERT]: (message, options) => {
    const timeout = options.variant === 'danger' ? 0 : 5
    return {
      id: options.id || parseInt(Math.random() * 1000),
      icon: options.icon,
      message,
      variant: options.variant || 'dark',
      timeout: typeof options.timeout === 'number' ? options.timeout : timeout
    }
  }
})
