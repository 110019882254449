// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const {
  getCompletedOrders,
  getActiveOrders,
  getOrderDetails,
  cancelOrder
} = createActions({
  [ActionTypes.GET_COMPLETED_ORDERS]: (skip, limit, searchString, startingDate, endingDate) => ({ skip, limit, searchString, startingDate, endingDate }),
  [ActionTypes.GET_ACTIVE_ORDERS]: (skip, limit, searchString, startingDate, endingDate) => ({ skip, limit, searchString, startingDate, endingDate }),
  [ActionTypes.GET_ORDER_DETAILS]: (id) => ({ id }),
  [ActionTypes.CANCEL_ORDER]: (id, message) => ({ id, message })
})
