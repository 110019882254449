import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  // ListSubheader,
  Typography,
  withStyles
} from '@material-ui/core'

// Material icons
import {
  DashboardOutlined as DashboardIcon,
  StoreOutlined as StoreIcon,
  // SupervisorAccountOutlined as SupervisorAccountIcon,
  InfoOutlined as InfoIcon,
  FiberNew as FiberNewIcon,
  LocationOnOutlined as LocationOnIcon,
  Assignment as AssignmentIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  MonochromePhotos as MonochromePhotosIcon
} from '@material-ui/icons'
import { logOut } from 'redux/actions/index'

// Component styles
import styles from './styles'

class Sidebar extends Component {
  logout = () => {
    const { dispatch } = this.props
    dispatch(logOut())
  }

  render () {
    const { classes, className } = this.props
    const rootClassName = classNames(classes.root, className)

    return (
      <nav className={rootClassName}>
        <div className={classes.logoWrapper}>
          <Link
            className={classes.logoLink}
            to='/'
          >
            <img
              alt='Shop logo'
              className={classes.logoImage}
              src='/images/logos/shopsapp.png'
            />
          </Link>
        </div>
        <Divider className={classes.logoDivider} />
        <div className={classes.profile}>
          <Typography
            className={classes.bioText}
            variant='caption'
          >
            {'Admin'}
          </Typography>
        </div>
        <Divider className={classes.profileDivider} />
        <List
          component='div'
          disablePadding
        >
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to='/dashboard'
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary='Dashboard'
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to='/new-shops'
          >
            <ListItemIcon className={classes.listItemIcon}>
              <FiberNewIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary='New Shops'
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to='/shops'
          >
            <ListItemIcon className={classes.listItemIcon}>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary='Shops'
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to='/active-orders'
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary='Active Orders'
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to='/completed-orders'
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AssignmentTurnedInIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary='Completed Orders'
            />
          </ListItem>

          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to='/master-table'
          >
            <ListItemIcon className={classes.listItemIcon}>
              <MonochromePhotosIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary='Master Table'
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to='/locations'
          >
            <ListItemIcon className={classes.listItemIcon}>
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary='Locations'
            />
          </ListItem>
          {/* <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to='/customers'
          >
            <ListItemIcon className={classes.listItemIcon}>
              <SupervisorAccountIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary='Customers'
            />
          </ListItem> */}
          <Divider className={classes.profileDivider} />
          <ListItem
            className={classes.listItem}
            component='div'
            onClick={this.logout}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary='Logout'
            />
          </ListItem>
        </List>
        <Divider className={classes.listDivider} />

      </nav>
    )
  }
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Sidebar))
