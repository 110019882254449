import React, { Component } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import { Typography, withStyles, Button } from '@material-ui/core'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'
import { Portlet, PortletContent } from 'components'
import { showAlert } from 'redux/actions'

import style from './style'

class ShopContact extends Component {
  chatWithShop = shop => {
    let anchor = document.createElement('a')
    anchor.href = `https://wa.me/91${shop.contact.customer_care || shop.contact.phone}`
    anchor.target = '_blank'
    document.body.appendChild(anchor)
    anchor.click()
  }

  copyShopLink = shop => {
    const link = `https://shopsapp.io/shops/${shop.shop_url}`
    let textField = document.createElement('textarea')
    textField.innerText = link
    document.body.appendChild(textField)
    textField.select()
    let success = document.execCommand('copy')
    textField.remove()
    success = success || document.execCommand('copy', false, link)
    if (success) {
      this.props.dispatch(showAlert('Copied shop link to clipboard!', { variant: 'success' }))
    } else {
      this.props.dispatch(showAlert('Failed to copy shop link to clipboard!', { variant: 'error' }))
    }
  }

  openShop = shop => {
    window.open(`https://shopsapp.io/shops/${shop.shop_url}`)
  }

  navigateToLocation = (shop) => {
    const address = shop.address
    let shopDirection = `https://www.google.com/maps/?q=${shop.name}%20${address.locality}`
    if (address.coordinates_full) {
      shopDirection = `https://www.google.com/maps/dir/@${address.coordinates_full.lat},${address.coordinates_full.long},16z`
    }
    let anchor = document.createElement('a')
    anchor.href = shopDirection
    anchor.target = '_blank'
    document.body.appendChild(anchor)
    anchor.click()
  }

  openDirectShop = (shop) => {
    const link = `https://${shop.shop_url}.shopsapp.io/`
    let textField = document.createElement('textarea')
    textField.innerText = link
    document.body.appendChild(textField)
    textField.select()
    let success = document.execCommand('copy')
    textField.remove()
    success = success || document.execCommand('copy', false, link)
    if (success) {
      this.props.dispatch(showAlert('Copied shop link to clipboard!', { variant: 'success' }))
    } else {
      this.props.dispatch(showAlert('Failed to copy shop link to clipboard!', { variant: 'error' }))
    }
  }

  render () {
    const { classes, className, shop, ...rest } = this.props
    const rootClassName = classNames(classes.root, className)
    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletContent>
          <div className={classes.details}>
            <div className={classes.info}>
              <Typography variant='h4'>Contact</Typography>
              <div className={classes.actions} >
                <Button
                  className={classes.button}
                  color='primary'
                  variant='contained'
                  onClick={() => this.copyShopLink(shop)}
                >
                  Get Shop Link
                </Button>
                <Button
                  className={classes.button}
                  color='secondary'
                  variant='contained'
                  onClick={() => this.chatWithShop(shop)}
                >
                  Whatsapp Shop
                </Button>
                <Button
                  className={classes.button}
                  color='primary'
                  variant='contained'
                  onClick={() => window.open(`tel:+91${shop.contact.customer_care}`)}
                >
                  Call
                </Button>
                <Button
                  className={classes.button}
                  color='secondary'
                  variant='contained'
                  onClick={() => this.openShop(shop)}
                >
                  Open Shop
                </Button>
                <Button
                  className={classes.button}
                  color='secondary'
                  variant='contained'
                  onClick={() => this.openDirectShop(shop)}
                >
                  Copy Shop Direct Link
                </Button>
                <Button color='secondary' className={classes.locationButton} variant='contained' onClick={() => this.navigateToLocation(shop)} > Location
                  <PersonPinCircleIcon className={classes.location} fontSize='large' />
                </Button>
              </div>
            </div>
          </div>
        </PortletContent>
      </Portlet >
    )
  }
}

ShopContact.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {}
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(ShopContact))
