import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Typography, withStyles } from '@material-ui/core'
import {
  // ArrowDownward as ArrowDownwardIcon,
  Store as StoreIcon
} from '@material-ui/icons'
import { Paper } from 'components'

// Component styles
import styles from './styles'

class NewShops extends Component {
  render () {
    const { classes, className, heading, count, ...rest } = this.props

    const rootClassName = classNames(classes.root, className)

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
              New Shops this week
            </Typography>
            <Typography
              className={classes.value}
              variant='h3'
            >
              {count}
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <StoreIcon className={classes.icon} />
          </div>
        </div>
        {/* <div className={classes.footer}>
          <Typography
            className={classes.difference}
            variant="body2"
          >
            <ArrowDownwardIcon />
            {}
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          >
            Since last month
          </Typography>
        </div> */}
      </Paper>
    )
  }
}

NewShops.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NewShops)
