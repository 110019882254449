// Palette
import palette from 'theme/palette'

export const availableColors = {
  confirmed: palette.danger.dark,
  shopConfirmed: palette.danger.main,
  shopPacked: palette.warning.main,
  shopDelivery: palette.success.main
}

export const statusMap = {
  confirmed: 'Order Placed',
  shopConfirmed: 'Shop Confirmed',
  shopDelivery: 'Shop Delivery',
  shopPacked: 'Packed Order'
}

// Chart data
export const data = {
  datasets: [
    {
      data: [],
      backgroundColor: [
        palette.danger.dark,
        palette.danger.main,
        palette.warning.main,
        palette.success.main
      ],
      borderWidth: 8,
      borderColor: palette.common.white,
      hoverBorderColor: palette.common.white
    }
  ],
  labels: [ 'Order Placed', 'Shop Confirmed', 'Packed Order', 'Shop Delivery' ]
}

// Chart options
export const options = {
  legend: {
    display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  cutoutPercentage: 80,
  layout: { padding: 0 },
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.border,
    backgroundColor: palette.common.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  }
}
