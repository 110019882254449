import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid, withStyles, CircularProgress, Typography } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import treeChanges from 'tree-changes'
// import treeChanges from 'tree-changes'

import { STATUS } from 'redux/constants/index'

import style from './style'
import { getShopDetails, verifyShop, showAlert, deleteShop } from 'redux/actions'
import { ShopProfile, ShopAddress, OrderStatus, DeleteShopConfirmation, ShopContact } from '..'
import ShopStatus from '../ShopStatus'

class Details extends Component {
  state = {
    showDelete: false
  }
  componentWillMount = () => {
    const { dispatch, shopId } = this.props
    dispatch(getShopDetails(shopId))
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('verify.status', STATUS.READY)) {
      this.props.dispatch(showAlert(`Shop Successfully ${newProps.verify.data.status ? 'Verified' : 'Disabled'}`, { variant: 'success' }))
    } else if (changedTo('verify.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.verify.message, { variant: 'error' }))
    }
    if (changedTo('delete.status', STATUS.READY)) {
      this.props.dispatch(showAlert('Shop Successfully Deleted', { variant: 'success' }))
      this.hideDeleteShop()
      this.props.history.push('/shops')
    } else if (changedTo('delete.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.delete.message, { variant: 'error' }))
    }
  }

  verifyShop = (status) => {
    const { shopId } = this.props
    this.props.dispatch(verifyShop(shopId, status))
  }

  hideDeleteShop = () => {
    this.setState({ showDelete: false, selectedShop: null })
  }

  showDelete = (shop) => {
    this.setState({ showDelete: true, selectedShop: shop })
  }

  deleteShop = id => {
    const { shopId } = this.props
    this.props.dispatch(deleteShop(shopId))
  }

  render = () => {
    const { shopDetails, classes } = this.props
    const { showDelete, selectedShop } = this.state
    if (shopDetails.status === STATUS.RUNNING || shopDetails.status === STATUS.IDLE) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )
    }

    if (shopDetails.status === STATUS.ERROR) {
      return (
        <Typography variant='h6'>There are no shops available for this details</Typography>
      )
    }
    return (
      <React.Fragment>
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <ShopProfile shop={shopDetails.data || {}} verifyShop={this.verifyShop} />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <ShopStatus status={shopDetails.data || {}} verifyShop={this.verifyShop} />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <ShopAddress shop={shopDetails.data || {}} deleteShop={this.showDelete} />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <OrderStatus status={shopDetails.data.stats || {}} />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
        >
          <ShopContact shop={shopDetails.data || {}} />
        </Grid>
        <DeleteShopConfirmation isOpen={showDelete} onSubmit={this.deleteShop} shop={selectedShop} onCancel={this.hideDeleteShop} />
      </React.Fragment>
    )
  }
}

Details.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    shopDetails: state.shops.details,
    verify: state.shops.verify,
    delete: state.shops.delete
  }
}

export default compose(
  withRouter,
  withStyles(style)
)(connect(mapStateToProps)(Details))
