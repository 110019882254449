import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import { parseError } from 'services/client'
import _ from 'lodash'

import { STATUS, ActionTypes } from 'redux/constants/index'

export const userState = {
  isAuthenticated: false,
  status: STATUS.IDLE,
  logoutStatus: STATUS.IDLE,
  authParams: {},
  userInfo: {},
  isUserPhoneVerified: false,
  message: ''
}

export default {
  user: handleActions(
    {
      [ActionTypes.USER_LOGIN]: (state, { payload }) =>
        immutable(state, {
          userInfo: { $set: payload },
          status: { $set: STATUS.RUNNING }
        }),
      [ActionTypes.USER_LOGIN_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          isAuthenticated: { $set: true },
          userInfo: { $set: payload },
          status: { $set: STATUS.READY }
        }),
      [ActionTypes.USER_LOGIN_FAILURE]: (state, { payload }) =>
        immutable(state, {
          isAuthenticated: { $set: false },
          message: { $set: parseError(payload) },
          status: { $set: STATUS.ERROR }
        }),

      [ActionTypes.SET_USER_INFO]: (state, { payload }) =>
        immutable(state, {
          userInfo: { $set: payload }
        }),

      [ActionTypes.USER_SIGNUP_FAILURE]: (state, { payload }) =>
        immutable(state, {
          isAuthenticated: { $set: false },
          message: { $set: parseError(payload.message) },
          signupStatus: { $set: STATUS.ERROR }
        }),
      [ActionTypes.USER_LOGOUT]: state =>
        immutable(state, {
          logoutStatus: { $set: STATUS.RUNNING }
        }),
      [ActionTypes.USER_LOGOUT_SUCCESS]: state =>
        immutable(state, {
          isAuthenticated: { $set: false },
          logoutStatus: { $set: STATUS.READY }
        }),
      [ActionTypes.USER_LOGOUT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          isAuthenticated: { $set: false },
          logoutStatus: { $set: STATUS.ERROR }
        }),
      [ActionTypes.REFRESH_TOKEN]: state =>
        immutable(state, {
          status: { $set: STATUS.IDLE }
        }),

      [ActionTypes.SET_AUTHENTICATED]: state =>
        immutable(state, {
          isAuthenticated: { $set: true }
        }),

      [ActionTypes.SET_SHOP_LOGO]: (state, { payload }) => {
        const userInfo = _.cloneDeep(state.userInfo)
        userInfo.shop.image_url = payload
        return immutable(state, {
          userInfo: { $set: userInfo }
        })
      }

    },
    userState
  )
}
