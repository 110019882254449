import React, { Component } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'
import { withStyles, TextField, Typography, Checkbox } from '@material-ui/core'
import { STATUS } from 'redux/constants'
import Modal from 'views/Modal'
import { showAlert, updateShopSlot, addShopSlot } from 'redux/actions/index'

// Component styles
const styles = theme => ({
  wrapper: {

  },
  time: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20
  },
  textField: {
    width: 80,
    marginLeft: 20
  },
  timeTitle: {
    width: 80
  },
  days: {
    display: 'flex',
    width: '100%'
  },
  day: {
    width: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  checkbox: {
    width: 40
  },
  dayTitle: {
    marginBottom: 10,
    width: 40,
    textAlign: 'center'
  },
  limit: {
    width: '50%',
    marginBottom: 30
  }
})

const defaultDetails = {
  start: {
    h: 0,
    m: 0
  },
  end: {
    h: 0,
    m: 0
  },
  days: []
}

class AddSlot extends Component {
  constructor (props) {
    super(props)
    this.state = {
      details: { ...defaultDetails, days: [props.slotDay] }
    }
  }

    componentWillReceiveProps = newProps => {
      const { changed, changedTo } = treeChanges(this.props, newProps)
      if (changed('slotId') && newProps.details) {
        this.setState({ details: newProps.details })
      } else if (changed('slotId')) {
        this.setState({ details: { ...defaultDetails, days: [newProps.slotDay] } })
      }
      if (changedTo('create.status', STATUS.READY)) {
        this.setState({ details: { ...defaultDetails, days: [this.props.slotDay] } })
        this.props.dispatch(showAlert('Created slot successfully', { variant: 'success' }))
        this.props.onSubmit()
      } else if (changedTo('update.status', STATUS.READY)) {
        this.setState({ details: { ...defaultDetails, days: [this.props.slotDay] } })
        this.props.dispatch(showAlert('Updated slot successfully', { variant: 'success' }))
        this.props.onSubmit()
      } else if (changedTo('create.status', STATUS.ERROR)) {
        this.setState({ details: { ...defaultDetails, days: [this.props.slotDay] } })
        this.props.onSubmit()
        this.props.dispatch(showAlert(newProps.create.message, { variant: 'error' }))
      } else if (changedTo('update.status', STATUS.ERROR)) {
        this.setState({ details: { ...defaultDetails, days: [this.props.slotDay] } })
        this.props.onSubmit()
        this.props.dispatch(showAlert(newProps.update.message, { variant: 'error' }))
      }
    }

    handleChange = (event, type, time) => {
      const { value } = event.target
      let { details } = this.state
      details[type][time] = isNaN(value) ? value : parseInt(value)
      this.setState({ details })
    }

    handleLimitChange = (event) => {
      const { value } = event.target
      let { details } = this.state
      details.limit = isNaN(value) ? value : parseInt(value)
      this.setState({ details })
    }

    onChangeDays = (event, day) => {
      const { checked } = event.target
      let { details } = this.state
      const index = details.days.indexOf(day)
      if (checked) {
        if (index === -1) {
          details.days.push(day)
          details.days.sort((a, b) => a - b)
        }
      } else {
        details.days.splice(index, 1)
      }
      this.setState({ details })
    }

    checkValidity = () => {
      const { details } = this.state
      if (details.start.h > 23 || details.start.h < 0 || details.start.m > 59 || details.start.m < 0) {
        this.props.dispatch(showAlert('Start Time is not valid', { variant: 'error' }))
        return false
      } else if (details.end.h > 23 || details.end.h < 0 || details.end.m > 59 || details.end.m < 0) {
        this.props.dispatch(showAlert('Start Time is not valid', { variant: 'error' }))
        return false
      } else if ((details.start.h * 60 + parseInt(details.start.m)) >= (details.end.h * 60 + parseInt(details.end.m))) {
        this.props.dispatch(showAlert('Time Range is not valid', { variant: 'error' }))
        return false
      }
      return true
    }

    onSubmit = () => {
      const { slotId } = this.props
      const { details } = this.state
      if (!details.days.length) {
        this.props.dispatch(showAlert('Active days cannot be empty', { variant: 'error' }))
        return
      }
      if (!details.limit) {
        this.props.dispatch(showAlert('Member Limit should be valid', { variant: 'error' }))
        return
      }
      const isValid = this.checkValidity()
      if (!isValid) {
        return
      }
      if (slotId) {
        this.onEditSlot()
      } else {
        this.onCreateSlot()
      }
    }

    onEditSlot = () => {
      const { slotId, shopId } = this.props
      const { details } = this.state
      const request = {
        ...details,
        duration: (details.end.h * 60 + parseInt(details.end.m)) - (details.start.h * 60 + parseInt(details.start.m))
      }
      this.props.dispatch(updateShopSlot(shopId, slotId, request))
    }

    onCreateSlot = () => {
      const { details } = this.state
      const request = {
        ...details,
        duration: (details.end.h * 60 + details.end.m) - (details.start.h * 60 + details.start.m)
      }
      this.props.dispatch(addShopSlot(this.props.shopId, request))
    }

    onCancel = () => {
      this.setState({ details: { ...defaultDetails, days: [this.props.slotDay] } })
      this.props.onCancel()
    }

    render () {
      const { classes, isOpen, slotId } = this.props
      const { details } = this.state
      const child = <div className={classes.container}>
        <div className={classes.time}>
          <Typography className={classes.timeTitle} variant='body1'>From</Typography>
          <TextField
            className={classes.textField}
            label='Start Hour'
            margin='dense'
            required
            onChange={(e) => this.handleChange(e, 'start', 'h')}
            value={details.start.h}
            inputProps={{ max: 23, min: 0 }}
            type='number'
            variant='outlined'
          />
          <TextField
            className={classes.textField}
            label='Start Minute'
            margin='dense'
            required
            onChange={(e) => this.handleChange(e, 'start', 'm')}
            value={details.start.m}
            inputProps={{ max: 59, min: 0 }}
            variant='outlined'
            type='number'
          />
        </div>
        <div className={classes.time}>
          <Typography className={classes.timeTitle} variant='body1'>To</Typography>
          <TextField
            className={classes.textField}
            label='Start Hour'
            margin='dense'
            required
            onChange={(e) => this.handleChange(e, 'end', 'h')}
            value={details.end.h}
            variant='outlined'
            type='number'
          />
          <TextField
            className={classes.textField}
            label='Start Minute'
            margin='dense'
            required
            onChange={(e) => this.handleChange(e, 'end', 'm')}
            value={details.end.m}
            variant='outlined'
            type='number'
          />
        </div>
        <TextField
          className={classes.limit}
          label='Member Limit'
          margin='dense'
          required
          onChange={this.handleLimitChange}
          value={details.limit}
          variant='outlined'
          type='number'
        />
        <div className={classes.days} >
          <div className={classes.day} >
            <Typography className={classes.dayTitle} variant='body1' >MON</Typography>
            <Checkbox
              className={classes.checkbox}
              checked={details.days.indexOf(0) > -1}
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              onChange={(e) => this.onChangeDays(e, 0)}
            />
          </div>
          <div className={classes.day} >
            <Typography className={classes.dayTitle} variant='body1' >TUE</Typography>
            <Checkbox
              className={classes.checkbox}
              checked={details.days.indexOf(1) > -1}
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              onChange={(e) => this.onChangeDays(e, 1)}
            />
          </div>
          <div className={classes.day} >
            <Typography className={classes.dayTitle} variant='body1' >WED</Typography>
            <Checkbox
              className={classes.checkbox}
              checked={details.days.indexOf(2) > -1}
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              onChange={(e) => this.onChangeDays(e, 2)}
            />
          </div>
          <div className={classes.day} >
            <Typography className={classes.dayTitle} variant='body1' >THU</Typography>
            <Checkbox
              className={classes.checkbox}
              checked={details.days.indexOf(3) > -1}
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              onChange={(e) => this.onChangeDays(e, 3)}
            />
          </div>
          <div className={classes.day} >
            <Typography className={classes.dayTitle} variant='body1' >FRI</Typography>
            <Checkbox
              className={classes.checkbox}
              checked={details.days.indexOf(4) > -1}
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              onChange={(e) => this.onChangeDays(e, 4)}
            />
          </div>
          <div className={classes.day} >
            <Typography className={classes.dayTitle} variant='body1' >SAT</Typography>
            <Checkbox
              className={classes.checkbox}
              checked={details.days.indexOf(5) > -1}
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              onChange={(e) => this.onChangeDays(e, 5)}
            />
          </div>
          <div className={classes.day} >
            <Typography className={classes.dayTitle} variant='body1' >SUN</Typography>
            <Checkbox
              className={classes.checkbox}
              checked={details.days.indexOf(6) > -1}
              color='primary'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              onChange={(e) => this.onChangeDays(e, 6)}
            />
          </div>
        </div>
      </div>

      return (
        <Modal isOpen={isOpen} title={slotId ? 'EDIT SLOT' : 'ADD NEW SLOT'} onCancel={this.onCancel} okText={'Save'} onSubmit={this.onSubmit} child={child} width={600} />
      )
    }
}

AddSlot.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    update: state.shops.updateSlot,
    create: state.shops.addSlot
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(AddSlot))
