import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import { parseError } from 'services/client'
import _ from 'lodash'

import { ActionTypes, STATUS } from 'redux/constants/index'

export const orderState = {
  completedOrders: {
    message: '',
    data: [],
    searchParams: {},
    status: STATUS.IDLE
  },
  activeOrders: {
    message: '',
    data: [],
    searchParams: {},
    status: STATUS.IDLE
  },
  details: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  cancelOrder: {
    message: '',
    data: {},
    status: STATUS.IDLE
  }
}

export default {
  orders: handleActions(
    {
      [ActionTypes.GET_ACTIVE_ORDERS]: (state, { payload }) =>
        immutable(state, {
          activeOrders: {
            status: { $set: STATUS.RUNNING },
            searchParams: { $set: payload },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.GET_ACTIVE_ORDERS_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.activeOrders.data)
        if (state.activeOrders.searchParams && state.activeOrders.searchParams.skip === 0) {
          data = payload
        } else {
          data = [ ...data, ...payload ]
        }
        return immutable(state, {
          activeOrders: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.activeOrders.searchParams.limit }
          }
        })
      },
      [ActionTypes.GET_ACTIVE_ORDERS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          activeOrders: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            searchParams: { $set: {} }
          }
        }),

      [ActionTypes.GET_COMPLETED_ORDERS]: (state, { payload }) =>
        immutable(state, {
          completedOrders: {
            status: { $set: STATUS.RUNNING },
            searchParams: { $set: payload },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.GET_COMPLETED_ORDERS_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.completedOrders.data)
        if (state.completedOrders.searchParams && state.completedOrders.searchParams.skip === 0) {
          data = payload
        } else {
          data = [ ...data, ...payload ]
        }
        return immutable(state, {
          completedOrders: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.completedOrders.searchParams.limit }
          }
        })
      },
      [ActionTypes.GET_COMPLETED_ORDERS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          completedOrders: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            searchParams: { $set: {} }
          }
        }),

      [ActionTypes.GET_ORDER_DETAILS]: (state, { payload }) =>
        immutable(state, {
          details: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_ORDER_DETAILS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          details: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.GET_ORDER_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          details: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CANCEL_ORDER]: (state, { payload }) =>
        immutable(state, {
          cancelOrder: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CANCEL_ORDER_SUCCESS]: (state, { payload }) => {
        let { activeOrders, completedOrders, details } = _.cloneDeep(state)
        const activeIndex = activeOrders.data.findIndex(item => item.id === payload.id)
        details.data.status = 'shop_cancelled'
        if (activeIndex > -1) {
          const order = activeOrders.data.splice(activeIndex, 1)
          if (order && order.length) {
            completedOrders.data.splice(0, order[0])
          }
        }

        return immutable(state, {
          details: { $set: details },
          completedOrders: { $set: completedOrders },
          activeOrders: { $set: activeOrders },
          cancelOrder: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CANCEL_ORDER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          cancelOrder: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })

    },
    orderState
  )
}
