import React from 'react'
import PropTypes from 'prop-types'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import { amber, green } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core/styles'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const useStyles = makeStyles(theme => ({
  container: {
    width: '250px',
    zIndex: 1400,
    position: 'absolute',
    right: 20,
    top: 100
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  snackbar: {
    display: 'inline-flex'
  },
  message: {
    alignItems: 'center',
    lineHeight: 18,
    height: 18,
    display: 'inline-flex'
  }
}))

export default function MessageToast (props) {
  const classes = useStyles()
  const { message, onClose, variant, open } = props
  const Icon = variantIcon[variant]
  return (<SnackbarContent
    className={`${classes[variant]} ${classes.container}`}
    aria-describedby='client-snackbar'
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    open={open}
    message={
      <span className={classes.snackbar} id='client-snackbar'>
        <Icon />
        <p className={classes.message}>{message}</p>
      </span>
    }
    action={[
      <IconButton key='close' aria-label='close' color='inherit' onClick={onClose}>
        <CloseIcon />
      </IconButton>
    ]}
  />
  )
}

MessageToast.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
}
