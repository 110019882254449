export default theme => ({
  root: {
    padding: theme.spacing.unit * 4,
    paddingBottom: 0
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  item: {
    height: '100%'
  },
  progressWrapper: {
    zIndex: 10,
    width: '100%',
    position: 'absolute',
    top: '500',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center'
  }
})
