import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'

import { store } from 'redux/store/index'

// Service worker
import * as serviceWorker from './common/serviceWorker'

// App
import App from './App'

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, document.getElementById('root')
)

serviceWorker.unregister()
