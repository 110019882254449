import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import { parseError } from 'services/client'
import _ from 'lodash'

import { ActionTypes, STATUS } from 'redux/constants/index'

export const productsState = {
  master: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  details: {
    message: '',
    data: {},
    status: STATUS.IDLE
  }
}

export default {
  products: handleActions(
    {
      [ActionTypes.GET_MASTER_TABLE_ITEMS]: (state, { payload }) =>
        immutable(state, {
          master: {
            status: { $set: STATUS.RUNNING },
            searchParams: { $set: payload },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.GET_MASTER_TABLE_ITEMS_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.master.data)
        if (state.master.searchParams && state.master.searchParams.skip === 0) {
          data = payload
        } else {
          data = [ ...data, ...payload ]
        }
        return immutable(state, {
          master: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.master.searchParams.limit }
          }
        })
      },
      [ActionTypes.GET_MASTER_TABLE_ITEMS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          master: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR },
            searchParams: { $set: {} }
          }
        }),

      [ActionTypes.GET_PRODUCT_DETAILS]: (state, { payload }) =>
        immutable(state, {
          details: {
            status: { $set: STATUS.RUNNING },
            searchParams: { $set: payload }
          }
        }),
      [ActionTypes.GET_PRODUCT_DETAILS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          details: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.GET_PRODUCT_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          details: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })

    },
    productsState
  )
}
