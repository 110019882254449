// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const { getMasterTableItems, getProductDetails } = createActions({
  [ActionTypes.GET_MASTER_TABLE_ITEMS]: (skip, limit, searchString) => ({ skip, limit, searchString }),
  [ActionTypes.GET_PRODUCT_DETAILS]: (id, isMaster, shopId) => ({ id, isMaster, shopId })
})
