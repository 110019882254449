import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core'

// Shared services
import { getOrders } from 'services/order'

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components'

// Component styles
import styles from './styles'

class TopViews extends Component {
  signal = false;

  state = {
    isLoading: false,
    limit: 10,
    orders: [],
    ordersTotal: 0
  };

  async getOrders (limit) {
    try {
      this.setState({ isLoading: true })

      const { orders, ordersTotal } = await getOrders(limit)

      if (this.signal) {
        this.setState({
          isLoading: false,
          orders,
          ordersTotal
        })
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        })
      }
    }
  }

  componentDidMount () {
    this.signal = true

    const { limit } = this.state

    this.getOrders(limit)
  }

  componentWillUnmount () {
    this.signal = false
  }

  render () {
    const { classes, className, details } = this.props
    const { isLoading, orders } = this.state

    const rootClassName = classNames(classes.root, className)
    const showOrders = !isLoading && orders.length > 0

    return (
      <Portlet className={rootClassName}>
        <PortletHeader noDivider>
          <PortletLabel
            title='Top views'
          />
        </PortletHeader>
        <PerfectScrollbar>
          <PortletContent
            className={classes.portletContent}
            noPadding
          >
            {isLoading && (
              <div className={classes.progressWrapper}>
                <CircularProgress />
              </div>
            )}
            {showOrders && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Shop</TableCell>
                    <TableCell align='left'>Views</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details.map(shop => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={shop.id}
                    >
                      <TableCell className={classes.customerCell}><Link to={`/shops/${shop._id}`}>{shop.shop.name}</Link></TableCell>
                      <TableCell className={classes.customerCell}>
                        {shop.count}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </PortletContent>
        </PerfectScrollbar>
      </Portlet>
    )
  }
}

TopViews.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TopViews)
