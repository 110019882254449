import React, { Component } from 'react'
import { Doughnut } from 'react-chartjs-2'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { IconButton, Typography, withStyles } from '@material-ui/core'
import {
  LaptopMac as LaptopMacIcon,
  // PhoneIphone as PhoneIphoneIcon,
  Refresh as RefreshIcon
  // TabletMac as TabletMacIcon
} from '@material-ui/icons'
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent
} from 'components'

import { data, options, availableColors, statusMap } from './chart'
import styles from './styles'

class DevicesChart extends Component {
  getColorPercentages = orders => {
    const total = parseInt(orders.total_open_orders)
    return {
      confirmed: orders.confirmed ? parseInt(orders.confirmed / total * 100) : 0,
      shopConfirmed: orders.shop_confirmed ? parseInt(orders.shop_confirmed / total * 100) : 0,
      shopPacked: orders.shop_packed ? parseInt(orders.shop_packed / total * 100) : 0,
      shopDelivery: orders.shop_delivery ? parseInt(orders.shop_delivery / total * 100) : 0
    }
  }

  render () {
    const { classes, className, orders, ...rest } = this.props

    const rootClassName = classNames(classes.root, className)
    const percentages = this.getColorPercentages(orders)
    let chartPercentage = _.cloneDeep(data)
    chartPercentage.datasets[0].data = [
      percentages.confirmed,
      percentages.shopConfirmed,
      percentages.shopPacked,
      percentages.shopDelivery
    ]
    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader noDivider>
          <PortletLabel title='Open Order Status' />
          <PortletToolbar>
            <IconButton
              className={classes.refreshButton}
              onClick={this.handleRefresh}
              variant='text'
            >
              <RefreshIcon />
            </IconButton>
          </PortletToolbar>
        </PortletHeader>
        <PortletContent>
          <div className={classes.chartWrapper}>
            <Doughnut
              data={chartPercentage}
              options={options}
            />
          </div>
          <div className={classes.stats}>
            {Object.keys(percentages).map((key) => <div key={key} className={classes.device}>
              <LaptopMacIcon className={classes.deviceIcon} />
              <Typography variant='body1'>{statusMap[key]}</Typography>
              <Typography
                style={{ color: availableColors[key] }}
                variant='h2'
              >
                {`${percentages[key]}%`}
              </Typography>
            </div>)}
            {/* <div className={classes.device}>
              <TabletMacIcon className={classes.deviceIcon} />
              <Typography variant="body1">Tablet</Typography>
              <Typography
                style={{ color: palette.danger.main }}
                variant="h2"
              >
                15%
              </Typography>
            </div>
            <div className={classes.device}>
              <PhoneIphoneIcon className={classes.deviceIcon} />
              <Typography variant="body1">Mobile</Typography>
              <Typography
                style={{ color: palette.warning.main }}
                variant="h2"
              >
                23%
              </Typography>
            </div> */}
          </div>
        </PortletContent>
      </Portlet>
    )
  }
}

DevicesChart.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DevicesChart)
