import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Typography, LinearProgress, withStyles } from '@material-ui/core'
import { InsertChartOutlined as InsertChartIcon } from '@material-ui/icons'
import { Paper } from 'components'
import styles from './styles'

class Progress extends Component {
  render () {
    const { classes, className, status, ...rest } = this.props

    const rootClassName = classNames(classes.root, className)

    let percentage = 0
    if (status.status === 'Active') {
      percentage = 80
    } else if (status.status === 'Not accepting orders') {
      percentage = 60
    } if (status.status === 'Hidden') {
      percentage = 50
    } if (status.status === 'Waiting for confirmation') {
      percentage = 30
    }

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
              PROGRESS
            </Typography>
            <Typography
              className={classes.value}
              variant='h3'
            >
              {`${percentage}%`}
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <InsertChartIcon className={classes.icon} />
          </div>
        </div>
        <div className={classes.footer}>
          <LinearProgress
            value={percentage}
            variant='determinate'
          />
        </div>
      </Paper>
    )
  }
}

Progress.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Progress)
