import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import { parseError } from 'services/client'

import { ActionTypes, STATUS } from 'redux/constants/index'

export const analyticsState = {
  dashboard: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  sales: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  stats: {
    message: '',
    data: {},
    status: STATUS.IDLE
  }
}

export default {
  analytics: handleActions(
    {
      [ActionTypes.GET_ANALYTICS]: (state, { payload }) =>
        immutable(state, {
          dashboard: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_ANALYTICS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          dashboard: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_ANALYTICS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          dashboard: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_SALES]: (state, { payload }) =>
        immutable(state, {
          sales: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_SALES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          sales: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_SALES_FAILURE]: (state, { payload }) =>
        immutable(state, {
          sales: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),
      [ActionTypes.GET_DASHBOARD_STATS]: (state, { payload }) =>
        immutable(state, {
          stats: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_DASHBOARD_STATS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          stats: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_DASHBOARD_STATS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          stats: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })
    },
    analyticsState
  )
}
