import React, { Component } from 'react'

// Externals
import PropTypes from 'prop-types'

// Material helpers
import { withStyles, Button, CircularProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'

// Component styles
import Modal from 'views/Modal'
import styles from './style'
// import EditProduct from '../EditProduct'
import { getResizedImage } from 'services/utility'
import { getProductDetails } from 'redux/actions/index'
import treeChanges from 'tree-changes'
import { STATUS } from 'redux/constants'

class ProductDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showEdit: false,
      selectedImage: 0
    }
    if (props.id && props.isOpen) {
      props.dispatch(getProductDetails(props.id, props.isMaster, props.shopId))
    }
  }

  componentWillReceiveProps = newProps => {
    const { changed, changedTo } = treeChanges(this.props, newProps)
    if (newProps.id && changed('isOpen') && newProps.isOpen) {
      newProps.dispatch(getProductDetails(newProps.id, newProps.isMaster, newProps.shopId))
    } else if (changedTo('productDetails.status') === STATUS.READY) {
      const product = newProps.productDetails.data
      const index = (product.images || []).findIndex(item => item.url === product.image_url)
      this.setState({ selectedImage: index })
    }
  }

  onEditProduct = () => {
    this.setState({ showEdit: true })
  }

  hideEdit = () => {
    this.setState({ showEdit: false })
  }

  setSelectedImage = index => {
    if (this.state.selectedImage !== index) {
      this.setState({ selectedImage: index })
    }
  }

  // changeStockStatus = (event) => {
  //   const { checked } = event.target
  //   const { productDetails } = this.props
  //   const request = {
  //     stock_status: checked,
  //     stock_count: 1,
  //     itemId: productDetails.data.id
  //   }
  //   this.props.dispatch(changeStockStatus(request))
  // }

  render () {
    const { classes, onCancel, isOpen, productDetails } = this.props
    const { selectedImage } = this.state
    const product = productDetails.data
    let child, selectedImageUrl
    if (selectedImage > -1 && selectedImage < (product.images || []).length) {
      selectedImageUrl = getResizedImage(product.images[selectedImage].url, 300, 300)
    }

    const footer = <div className={classes.footer} >
      <div className={classes.buttonContainer} >
        {/* <Button className={classes.editButton} size='small' color='primary' variant='contained' onClick={this.onEditProduct}>
          Edit
        </Button> */}
        <Button className={classes.editButton} size='small' color='primary' variant='outlined' onClick={onCancel}>
          Close
        </Button>
      </div>
    </div>

    if (productDetails.status === STATUS.RUNNING) {
      child = <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    } else {
      child = <div className={classes.container}>
        {(product.images || []).length ? <div className={classes.imageContainer}>
          <img className={classes.image} src={selectedImageUrl} alt={product.name} />
          <div className={classes.list}>
            <div className={classes.imageListContainer}>
              {(product.images || []).map((item, index) => {
                const resizedImage = getResizedImage(item.url || item.imagePreviewUrl, 150, 150)
                return <div className={`${classes.miniImage} ${index === selectedImage ? classes.selected : ''}`} style={{ backgroundImage: `url(${resizedImage})` }} onClick={() => this.setSelectedImage(index)} />
              })}
            </div>

          </div>
        </div> : null}
        <div className={classes.details} >
          <div className={classes.detailsCell} >
            <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Name: </Typography>
            <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>{product.name || '-'}</Typography>
          </div>
          <div className={classes.detailsCell} >
            <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Manufacturer:</Typography>
            <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>{product.manufacturer || '-'}</Typography>
          </div>
          <div className={classes.detailsCell} >
            <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Desciption:</Typography>
            <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>{product.details || '-'}</Typography>
          </div>
          <div className={classes.detailsCell} >
            <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Model:</Typography>
            <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>{product.model || '-'}</Typography>
          </div>
          <div className={classes.detailsCell} >
            <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Item Code(SKU):</Typography>
            <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>{product.item_code || '-'}</Typography>
          </div>
          <div className={classes.detailsCell} >
            <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Rank:</Typography>
            <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>{product.rank || '-'}</Typography>
          </div>
          <div className={classes.detailsCell} >
            <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Price:</Typography>
            { product.price ? <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>&#8377;{product.price}</Typography> : <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>-</Typography>}
          </div>
          <div className={classes.detailsCell} >
            <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Actual Price:</Typography>
            { product.actual_price ? <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>&#8377;{product.actual_price}</Typography> : <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>-</Typography>}
          </div>
        </div>

      </div>
    }

    return (
      <React.Fragment >
        {/* <EditProduct isOpen={showEdit} onCancel={this.hideEdit} id={product ? product.id : null} onSubmit={this.hideEdit} /> */}
        <Modal isOpen={isOpen} title={'PRODUCT DETAILS'} child={child} footer={footer} />
      </React.Fragment>

    )
  }
}

ProductDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    productDetails: state.products.details
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ProductDetails))
