import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button, withStyles, Typography, Switch, TextField } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import treeChanges from 'tree-changes'
import _ from 'lodash'

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components'
import { updateShopSettings, showAlert } from 'redux/actions'
import { STATUS } from 'redux/constants'

const styles = theme => ({
  root: {},
  field: {
    margin: theme.spacing.unit * 3
  },
  portletFooter: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  }
})

class OrderSettings extends Component {
  constructor (props) {
    super(props)
    const shopSettings = props.settings.data
    this.state = {
      shopSettings,
      touched: false
    }
  }

  componentWillReceiveProps = newProps => {
    const { settings, updateShop } = _.cloneDeep(newProps)
    const { changed, changedTo } = treeChanges(this.props, newProps)
    if (changed('settings') && updateShop.status !== STATUS.RUNNING) {
      this.setState({ shopSettings: _.cloneDeep(settings.data) })
    }
    if (changedTo('updateShop.status', STATUS.ERROR) && updateShop.type === 'settings') {
      this.props.dispatch(showAlert(updateShop.message, { variant: 'error' }))
    } else if (changedTo('updateShop.status', STATUS.READY) && updateShop.type === 'settings') {
      this.setState({ touched: false })
      this.props.dispatch(showAlert('Updated Shop Reservation Settings successfully', { variant: 'success' }))
    }
  }

  handleReservationSettingsChange = (e, field) => {
    const { checked } = e.target
    let { shopSettings } = this.state
    shopSettings.settings[field] = checked
    this.setState({ shopSettings, touched: true })
  }

  handleReservationSettingsInputChange = (field, value) => {
    let { shopSettings } = this.state
    shopSettings.settings[field] = value
    this.setState({ shopSettings, touched: true })
  }

  handleSaveAction = e => {
    const { shopSettings } = this.state
    const request = {
      info: shopSettings.info,
      payment_details: shopSettings.payment_details,
      settings: {
        hide_stock: shopSettings.settings.hide_stock,
        manage_stock: shopSettings.settings.manage_stock,
        order_buffer: shopSettings.settings.order_buffer,
        order_confirmation_time: shopSettings.settings.order_confirmation_time,
        order_limited: shopSettings.settings.order_limited,
        order_packing_time: shopSettings.settings.order_packing_time
      }
    }
    this.props.dispatch(updateShopSettings(this.props.shopId, { shop: request }, 'settings'))
  }

  render () {
    const { classes, className, updateShop, ...rest } = this.props
    const { shopSettings, touched } = this.state
    const rootClassName = classNames(classes.root, className)

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            title='Order and Stock Settings'
          />
        </PortletHeader>
        <PortletContent noPadding>
          <form
            autoComplete='off'
            noValidate
          >
            <div className={classes.field}>
              <div className={classes.acceptStatus}>
                <Typography className={classes.statusTitle} variant='body1'>Hide Stock</Typography>
                <Switch
                  className={classes.switch}
                  checked={shopSettings.settings.hide_stock}
                  onChange={(e) => this.handleReservationSettingsChange(e, 'hide_stock')}
                  value='checkedA'
                  // disabled={isLoading}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>
            <div className={classes.field}>
              <div className={classes.acceptStatus}>
                <Typography className={classes.statusTitle} variant='body1'>Manage Stock</Typography>
                <Switch
                  className={classes.switch}
                  checked={shopSettings.settings.manage_stock}
                  onChange={(e) => this.handleReservationSettingsChange(e, 'manage_stock')}
                  value='checkedA'
                  // disabled={isLoading}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>
            <div className={classes.field}>
              <div className={classes.acceptStatus}>
                <TextField
                  className={classes.textField}
                  label='Order Buffer(Hours)'
                  margin='dense'
                  value={shopSettings.settings.order_buffer}
                  variant='outlined'
                  type='number'
                  onChange={(e) => this.handleReservationSettingsInputChange('order_buffer', e.target.value)}
                  fullWidth
                />
              </div>
            </div>
            <div className={classes.field}>
              <div className={classes.acceptStatus}>
                <TextField
                  className={classes.textField}
                  label='Order Confirmation Time(Hours)'
                  margin='dense'
                  value={shopSettings.settings.order_confirmation_time}
                  variant='outlined'
                  type='number'
                  onChange={(e) => this.handleReservationSettingsInputChange('order_confirmation_time', e.target.value)}
                  fullWidth
                />
              </div>
            </div>
            <div className={classes.field}>
              <div className={classes.acceptStatus}>
                <Typography className={classes.statusTitle} variant='body1'>Order Limited</Typography>
                <Switch
                  className={classes.switch}
                  checked={shopSettings.settings.order_limited}
                  onChange={(e) => this.handleReservationSettingsChange(e, 'order_limited')}
                  value='checkedA'
                  // disabled={isLoading}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>
            <div className={classes.field}>
              <div className={classes.acceptStatus}>
                <TextField
                  className={classes.textField}
                  label='Order Packing Time(Hours)'
                  margin='dense'
                  value={shopSettings.settings.order_packing_time}
                  variant='outlined'
                  type='number'
                  onChange={(e) => this.handleReservationSettingsInputChange('order_packing_time', e.target.value)}
                  fullWidth
                />
              </div>
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSaveAction}
            disabled={!touched || (updateShop.status === STATUS.RUNNING && updateShop.type === 'settings')}
          >
            Save Settings
          </Button>
        </PortletFooter>
      </Portlet>
    )
  }
}

OrderSettings.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    settings: _.cloneDeep(state.shops.settings),
    updateShop: state.shops.updateSettings
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(OrderSettings))
