import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button, Divider, TextField, withStyles } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import treeChanges from 'tree-changes'
import _ from 'lodash'

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components'
import styles from './style'
import { updateShopSettings, showAlert } from 'redux/actions'
import { STATUS } from 'redux/constants'
import Select from 'components/Select'

const orderStatus = [
  {
    id: 'confirmed',
    type: 'On Checkout'
  }, {
    id: 'shop_confirmed',
    type: 'After Shop Confirmed'
  }, {
    id: 'packed',
    type: 'After Packed'
  }
]

class Payment extends Component {
  constructor (props) {
    super(props)
    const shopSettings = props.settings.data
    this.state = {
      shopSettings,
      touched: false
    }
  }

  componentWillReceiveProps = newProps => {
    const { settings, updateShop } = _.cloneDeep(newProps)
    const { changed, changedTo } = treeChanges(this.props, newProps)
    if (changed('settings') && updateShop.status !== STATUS.RUNNING) {
      this.setState({ shopSettings: _.cloneDeep(settings.data) })
    }
    if (changedTo('updateShop.status', STATUS.ERROR) && updateShop.type !== 'settings') {
      this.props.dispatch(showAlert(updateShop.message, { variant: 'error' }))
    } else if (changedTo('updateShop.status', STATUS.READY) && updateShop.type !== 'settings') {
      this.setState({ touched: false })
      this.props.dispatch(showAlert('Updated Shop Settings successfully', { variant: 'success' }))
    }
  }

  handleFieldChange = (field, value) => {
    let { shopSettings, touched } = this.state
    shopSettings.info[field] = value
    touched = true
    this.setState({ shopSettings, touched })
  }

  handlePaymentFieldChange = (field, value) => {
    let { shopSettings, touched } = this.state
    if (field === 'vpa') {
      shopSettings.payment_details.upi[field] = value
    } else if (field === 'type_string' || field === 'type' || field === 'payment_order_status') {
      shopSettings.payment_details[field] = value
    } else {
      shopSettings.payment_details.payment_gateway[field] = value
    }
    touched = true
    this.setState({ shopSettings, touched })
  }

  handleSaveAction = e => {
    const { shopSettings } = this.state
    const request = {
      info: {
        details: shopSettings.info.details || '',
        notice: shopSettings.info.notice || ''
      },
      payment_details: {
        type: shopSettings.payment_details.type || 'online',
        type_string: shopSettings.payment_details.type_string || 'Online',
        payment_order_status: shopSettings.payment_details.payment_order_status || 'confirmed',
        upi: {
          vpa: shopSettings.payment_details.upi.vpa || ''
        },
        payment_gateway: {
          encryption_key: shopSettings.payment_details.payment_gateway.encryption_key || '',
          merchant_id: shopSettings.payment_details.payment_gateway.merchant_id || '',
          sub_merchant_id: shopSettings.payment_details.payment_gateway.sub_merchant_id || '',
          gateway: 'icici',
          payment_modes: '9'
        }
      },
      settings: shopSettings.settings
    }
    this.props.dispatch(updateShopSettings(this.props.shopId, { shop: request }))
  }

  render () {
    const { classes, className, updateShop, ...rest } = this.props
    const { shopSettings, touched } = this.state
    const rootClassName = classNames(classes.root, className)

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            subtitle='The information can be edited'
            title='Settings'
          />
        </PortletHeader>
        <PortletContent noPadding>
          <form
            autoComplete='off'
            noValidate
          >
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label='Store Description'
                margin='dense'
                value={shopSettings.info.details}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('details', e.target.value)}
                multiline
                rows={3}
              />
              <TextField
                className={classes.textField}
                label='User Notice'
                margin='dense'
                value={shopSettings.info.notice}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('notice', e.target.value)}
              />
            </div>
            <Divider />
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label='Type String'
                margin='dense'
                value={shopSettings.payment_details ? shopSettings.payment_details.type_string : ''}
                variant='outlined'
                onChange={(e) => this.handlePaymentFieldChange('type_string', e.target.value)}
              />
              <TextField
                className={classes.textField}
                label='Type'
                margin='dense'
                value={shopSettings.payment_details ? shopSettings.payment_details.type : ''}
                variant='outlined'
                onChange={(e) => this.handlePaymentFieldChange('type', e.target.value)}
              />
            </div>
            <div className={classes.field}>
              <Select placeholder='When to Pay' options={orderStatus} valueParam='type' keyParam='id' selectedValue={shopSettings.payment_details.payment_order_status} handleChange={(event) => this.handlePaymentFieldChange('payment_order_status', event.target.value)} />
              <TextField
                className={classes.textField}
                label='UPI ID'
                margin='dense'
                value={shopSettings.payment_details && shopSettings.payment_details.upi ? shopSettings.payment_details.upi.vpa : ''}
                variant='outlined'
                onChange={(e) => this.handlePaymentFieldChange('vpa', e.target.value)}
              />
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label='Merchant ID'
                margin='dense'
                value={shopSettings.payment_details && shopSettings.payment_details.payment_gateway ? shopSettings.payment_details.payment_gateway.merchant_id : ''}
                variant='outlined'
                onChange={(e) => this.handlePaymentFieldChange('merchant_id', e.target.value)}
              />
              <TextField
                className={classes.textField}
                label='Sub Merchant ID'
                margin='dense'
                value={shopSettings.payment_details && shopSettings.payment_details.payment_gateway ? shopSettings.payment_details.payment_gateway.sub_merchant_id : ''}
                variant='outlined'
                onChange={(e) => this.handlePaymentFieldChange('sub_merchant_id', e.target.value)}
              />
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label='Encryption Key'
                margin='dense'
                value={shopSettings.payment_details && shopSettings.payment_details.payment_gateway ? shopSettings.payment_details.payment_gateway.encryption_key : ''}
                variant='outlined'
                onChange={(e) => this.handlePaymentFieldChange('encryption_key', e.target.value)}
              />
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSaveAction}
            disabled={!touched || (updateShop.status === STATUS.RUNNING && updateShop.type !== 'settings')}
          >
            Save Settings
          </Button>
        </PortletFooter>
      </Portlet>
    )
  }
}

Payment.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    settings: _.cloneDeep(state.shops.settings),
    updateShop: state.shops.updateSettings
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(Payment))
