import React, { Component } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { connect } from 'react-redux'
import treeChanges from 'tree-changes'

// Externals
import { Chart } from 'react-chartjs-2'

// Material helpers
import { ThemeProvider } from '@material-ui/styles'

// ChartJS helpers
import { chartjs } from './helpers'

// Theme
import theme from './theme'

// Styles
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/index.scss'

// Routes
import Routes from './Routes'
import MessageToast from './views/MessageToast'
import { hideAlert, refreshToken } from 'redux/actions'

// Browser history
const browserHistory = createBrowserHistory()

// Configure ChartJS
Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
})

class App extends Component {
  componentDidMount () {
    const { dispatch } = this.props
    dispatch(refreshToken())
    this.interval = setInterval(() => dispatch(refreshToken()), 45 * 60 * 1000)
  }

  componentWillReceiveProps (nextProps) {
    const { dispatch } = this.props
    const { changedTo } = treeChanges(this.props, nextProps)

    /* istanbul ignore else */
    if (changedTo('user.isAuthenticated', true)) {
      this.interval = setInterval(() => dispatch(refreshToken()), 45 * 60 * 1000)
    }
  }

  onClose = () => {
    this.props.dispatch(hideAlert())
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  onLogout = () => {
    clearInterval(this.interval)
  };

  render () {
    const { message, data, showAlert } = this.props.app
    return (
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
        { showAlert ? <MessageToast message={message} variant={data.variant} onClose={this.onClose} /> : null}
        {/* <MessageToast open={false} message={'You are too late ajesh'} variant={'warning'} onClose={() => {}} className='toast' /> */}
      </ThemeProvider>
    )
  }
}

function mapStateToProps (state) {
  return {
    app: state.app.alert,
    user: state.user
  }
}
export default connect(mapStateToProps)(App)
