export default theme => ({
  root: {
    padding: '15px 20px',
    borderColor: '#0767DB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    height: 72,
    width: '100%'
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:first-child p': {
      color: 'green'
    },
    '&:nth-child(2) p': {
      color: '#da7171'
    },
    '&:nth-child(3) p': {
      color: '#0767DB;'
    }
  },
  title: {
    color: 'black',
    fontWeight: 700,
    width: '50%',
    lineHeight: '20px',
    fontSize: 14

  },
  value: {
    width: '50%',
    textAlign: 'right',
    fontWeight: 700,
    fontSize: 14
  }
})
