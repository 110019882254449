import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import { parseError } from 'services/client'

import { ActionTypes, STATUS } from 'redux/constants/index'

export const usersState = {
  list: {
    message: '',
    data: [],
    status: STATUS.IDLE
  }
}

export default {
  users: handleActions(
    {
      [ActionTypes.GET_CUSTOMERS]: (state, { payload }) =>
        immutable(state, {
          list: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_CUSTOMERS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          list: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_CUSTOMERS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          list: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })
    },
    usersState
  )
}
