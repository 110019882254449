import React from 'react'

// Externals
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { InputLabel, Input, MenuItem, FormControl, Select as SelectInput, Checkbox, withStyles } from '@material-ui/core'

const styles = () => ({
  root: {
    width: '100%'
  },
  block: {
    cursor: 'no-drop'
  },
  disabled: {
    userSelect: 'none',
    pointerEvents: 'none'
  }
})

const Select = props => {
  const { classes, className, options, placeholder, selectedValue, valueParam, keyParam, multiple, handleChange } = props
  const disabled = !options || !options.length
  const rootClassName = classNames(classes.root, className, disabled ? classes.block : null)

  return (
    <FormControl className={rootClassName}>
      <InputLabel>{placeholder}</InputLabel>
      <SelectInput
        className={disabled ? classes.disabled : ''}
        value={selectedValue}
        onChange={handleChange}
        input={<Input variant='outlined' />}
        multiple={multiple}
        variant='outlined'
        renderValue={selected => {
          if (selected.length === 0) {
            return <em>Placeholder</em>
          }
          if (multiple) {
            return (options || []).filter(item => selected.find(item2 => item2 === item[keyParam])).map(item => item[valueParam]).join(', ')
          } else {
            const selectedOption = (options || []).find(item => item[keyParam] === selected)
            return selectedOption ? selectedOption[valueParam] : selected
          }
        }}
      >
        {options.map(option => (
          <MenuItem key={option[keyParam]} value={option[keyParam]}>
            { multiple ? <Checkbox checked={selectedValue.indexOf(option[keyParam]) > -1} /> : null }
            {option[valueParam]}
          </MenuItem>
        ))}
      </SelectInput>
    </FormControl>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  style: PropTypes.object
}

Select.defaultProps = {
  onChange: () => { },
  options: [],
  valueParam: 'name',
  keyParam: 'id',
  placeholder: 'Select'
}

export default withStyles(styles)(Select)
