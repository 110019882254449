import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, TextField, Typography } from '@material-ui/core'
import Modal from 'views/Modal'

const styles = theme => ({
  container: {
    // display: 'flex',
    '& .react-datetime-picker': {
      width: '100%',
      height: 35,
      marginTop: 20,
      '& > div': {
        borderRadius: '2px'
      }
    }
  },
  priceContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between'
  },
  textField: {
    width: '100%'
  },
  priceTextField: {
    width: 'calc( 50% - 10px)'
  },
  switchContainer: {
    width: 'calc( 50% - 10px)',
    display: 'flex',
    flexDirection: 'column'
    // justifyContent: 'center'
  },
  error: {
    color: 'red'
  },
  editableDesc: {
    fontSize: 13
  }
})

class ConfirmCancelOrder extends Component {
  state = {
    message: 'Order automatically cancelled because there was no response from shop'
  }

  handleChange = e => {
    const { value } = e.target
    this.setState({ message: value })
  }

  toggleEditable = (event) => {
    const { checked } = event.target
    this.setState({ isEditable: checked })
  }

  onSubmit = () => {
    const { message } = this.state
    const { onSubmit } = this.props
    this.setState({ error: false })
    if (!message) {
      this.setState({ error: true })
      return
    }
    onSubmit(message)
  }

  render () {
    const { classes, onCancel, isOpen } = this.props
    const { message, error } = this.state
    const child = <div className={classes.container}>
      {/* <Typography className={classes.warning} variant='p'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. of Lorem Ipsum.</Typography> */}
      <TextField
        className={classes.textField}
        label={'Reason'}
        margin='dense'
        onChange={this.handleChange}
        value={message}
        variant='outlined'
      />
      {error ? <Typography className={classes.error} variant='p'>Please Fill the Reason</Typography> : null}
    </div>
    return (
      <Modal isOpen={isOpen} title={'Cancel Order'} onCancel={onCancel} okText={'Done'} onSubmit={this.onSubmit} child={child} />
    )
  }
}

ConfirmCancelOrder.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

export default withStyles(styles)(ConfirmCancelOrder)
