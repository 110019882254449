export default theme => ({

  progressWrapper: {
    zIndex: 10,
    width: '100%',
    position: 'absolute',
    top: '500',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center'
  },
  orderSettings: {
    marginTop: '10px'
  }
})
