import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withStyles, CircularProgress, Typography } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import _ from 'lodash'
// import treeChanges from 'tree-changes'

import { STATUS } from 'redux/constants/index'

import style from './style'
import { getShopSlots } from 'redux/actions'
import { SlotList } from '..'

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thurday', 'Friday', 'Saturday', 'Sunday']

class SlotSettings extends Component {
  componentWillMount = () => {
    const { dispatch, shopId, slots } = this.props
    if (slots.data.id !== shopId) {
      dispatch(getShopSlots(shopId))
    }
  }

  getWeeklySlots = slots => {
    const weeks = [...Array(7)].map((i, index) => {
      const slot = slots.filter(item => item.days.indexOf(index) > -1).sort((a, b) => {
        const timeA = a.start.h * 60 + a.start.m
        const timeB = b.start.h * 60 + b.start.m
        return timeA - timeB
      })
      return {
        day: days[index],
        slot,
        slotDay: index
      }
    })
    return weeks
  }

  render = () => {
    const { classes, slots, shopId } = this.props
    if (slots.status === STATUS.RUNNING || slots.status === STATUS.IDLE) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )
    }

    if (slots.status === STATUS.ERROR) {
      return (
        <Typography variant='h6'>There are no shops available for this details</Typography>
      )
    }
    const weeks = this.getWeeklySlots(slots.data)
    return (
      <div className={classes.list} >
        {weeks.map(day => <SlotList key={day.slotDay} shopId={shopId} heading={day.day} slotDay={day.slotDay} slots={day.slot} />)}
      </div>
    )
  }
}

SlotSettings.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    slots: _.cloneDeep(state.shops.slots)
  }
}

export default compose(
  withRouter,
  withStyles(style)
)(connect(mapStateToProps)(SlotSettings))
