export default theme => ({
  root: {
    paddingBottom: 20
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.unit
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing.unit
  },
  dateFilterButton: {
    margin: '0px 10px 0'
  },
  dateFilterContainer: {
    '& .MuiPopover-paper': {
      overflow: 'hidden',
      padding: '20px 30px 20px'
    }
  }
})
