import { all, fork } from 'redux-saga/effects'

import auth from './auth'
import app from './app'
import shops from './shops'
import users from './users'
import analytics from './analytics'
import orders from './orders'
import products from './products'

/**
 * rootSaga
 */
export default function * root () {
  yield all([
    fork(auth),
    fork(app),
    fork(shops),
    fork(users),
    fork(analytics),
    fork(orders),
    fork(products)
  ])
}
