export default theme => ({
  root: {
  },
  details: {
    display: 'flex'
  },
  title: {

  },
  info: {},
  locationText: {
    marginTop: theme.spacing.unit,
    color: theme.palette.text.primary,
    fontWeight: 'bold'
  },
  dateText: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  },
  avatar: {
    position: 'relative',
    marginLeft: 'auto',
    height: '110px',
    width: '110px',
    flexShrink: 0,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '50%',
    flexGrow: 0,
    '&:hover > div': {
      display: 'flex'
    }
  },
  progressWrapper: {
    marginTop: theme.spacing.unit * 2
  },
  uploadButton: {
    marginRight: theme.spacing.unit * 2
  },
  portletFooter: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 1
  }
})
