import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { EditOutlined as EditIcon, DeleteOutlined as DeleteIcon } from '@material-ui/icons'

// Component styles
const styles = theme => ({
  wrapper: {
    width: '100%',
    height: 60,
    padding: 20,
    position: 'relative',
    borderBottom: '1px solid #ccc',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  selected: {
    background: '#ececec'
  },
  button: {
    position: 'absolute',
    right: 50,
    top: 20
  },
  deleteButton: {
    position: 'absolute',
    right: 20,
    top: 20
  }
})

class SlotListCard extends Component {
  getFormattedMinutes = m => {
    if (m > 9) return m
    return `0${m}`
  }

  render () {
    const { classes, item, onEdit, onDelete } = this.props

    return (
      <div className={`${classes.wrapper}`}>
        <p>{`${item.start.h}:${this.getFormattedMinutes(item.start.m)} - ${item.end.h}:${this.getFormattedMinutes(item.end.m)}`}</p>
        <EditIcon className={classes.button} onClick={onEdit} />
        <DeleteIcon className={classes.deleteButton} onClick={onDelete} />
      </div>
    )
  }
}

SlotListCard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SlotListCard)
