import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { Dashboard as DashboardLayout } from 'layouts'
import { withRouter } from 'react-router-dom'
import treeChanges from 'tree-changes'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  Grid,
  CircularProgress
} from '@material-ui/core'
import { Portlet, PortletContent } from 'components'
import styles from './style'
import { getMasterTableItems } from 'redux/actions'
import { STATUS } from 'redux/constants'
import SearchToolbar from './../../common/SearchToolbar'
import { getResizedImage } from 'services/utility'
import ProductDetails from 'common/ProductDetails'

const itemsPerPage = 20

class MasterTable extends Component {
    state = {
      page: 0
    }

    componentDidMount = () => {
      if (this.props.products.status === STATUS.IDLE) {
        this.props.dispatch(getMasterTableItems(0, itemsPerPage))
      } else {
        const searchString = this.props.products.searchParams.searchString
        const page = this.props.products.searchParams.skip ? this.props.products.searchParams.skip / this.props.products.searchParams.limit : 0
        this.setState({ page, searchString })
      }
    }

    componentWillReceiveProps = newProps => {
      const { changedTo } = treeChanges(this.props, newProps)
      if (changedTo('products.status', STATUS.READY)) {
        this.setState({ isLoading: false })
      }
    }

    onChangeSearchString = event => {
      const { value } = event.target
      this.setState({ searchString: value })
    }

    onClickSearch = () => {
      const { searchString } = this.state
      this.setState({ isLoading: true, page: 0 })
      this.props.dispatch(getMasterTableItems(0, itemsPerPage, searchString))
    }

    refreshData = () => {
      const { isLoading, page, searchString } = this.state
      const nextIndex = (page + 1) * itemsPerPage
      if (!isLoading) {
        this.setState({ isLoading: true }, () => {
          this.props.dispatch(getMasterTableItems(0, nextIndex, searchString))
        })
      }
    }

    onReachedBottom = () => {
      const { products } = this.props
      const { isLoading, page, searchString } = this.state
      const nextIndex = (page + 1) * itemsPerPage
      if (!isLoading && !products.reachedEnd) {
        this.setState({ page: page + 1, isLoading: true }, () => {
          this.props.dispatch(getMasterTableItems(nextIndex, itemsPerPage, searchString))
        })
      }
    }

    onSelectDetails = id => {
      // this.setState({ isOpen: true, selectedProduct: id})
    }

    hideDetails = () => {
      this.setState({ isOpen: false, selectedProduct: null })
    }

    getProductList () {
      const { classes, className, products } = this.props

      const rootClassName = classNames(classes.tableRoot, className)
      if (products.status === STATUS.RUNNING && products.data.length === 0) {
        return (
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        )
      }

      if (products.data.length === 0) {
        return (
          <Typography variant='h6'>No Master table items Found</Typography>
        )
      }
      return (
        <Portlet className={rootClassName}>
          {products.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
            <CircularProgress />
          </div> : null }
          <PortletContent className={classes.tableContainer} noPadding>
            {products.data.length ? <PerfectScrollbar onYReachEnd={this.onReachedBottom}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align='left' />
                    <TableCell align='left'>Name</TableCell>
                    <TableCell align='left'>Manufacturer</TableCell>
                    <TableCell align='left'>Model</TableCell>
                    <TableCell align='left'>Price</TableCell>
                    <TableCell align='left'>No of Images</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.data
                    .map(product => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={product.name}
                        onClick={() => this.onSelectDetails(product.id)}
                      >
                        <TableCell className={classes.tableCell}>
                          { product.image_url ? <img className={classes.logo} src={getResizedImage(product.image_url, 50, 50)} alt={product.name} /> : 'N/A' }
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {product.name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {product.manufacturer}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {product.modal}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {product.price}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {(product.images || []).length}
                        </TableCell>
                      </TableRow>
                    ))}

                </TableBody>
              </Table>
            </PerfectScrollbar> : null}
            {!products.data.length && products.status === STATUS.READY
              ? <Typography
                className={classes.error}
                hover
              >No Master table items Found</Typography> : null}
          </PortletContent>
        </Portlet>
      )
    }

    render () {
      const { searchString, isOpen, selectedProduct } = this.state
      const { classes } = this.props
      return (
        <DashboardLayout title='Master Table'>
          <div className={classes.root}>
            <Grid
              container
              className={classes.container}
              spacing={4}
            >
              <SearchToolbar searchString={searchString} onChangeSearchString={this.onChangeSearchString} onClickSearch={this.onClickSearch} onClickRefreshButton={this.refreshData} />
              {this.getProductList()}
            </Grid>
          </div>

          <ProductDetails isOpen={isOpen} id={selectedProduct} isMaster onCancel={this.hideDetails} onSubmit={this.hideDetails} />
          {/* <AddPartner isOpen={showAddDeliveryPerson} onCancel={this.hideAddDeliveryPerson} onSubmit={this.onSubmitPartner} /> */}
        </DashboardLayout>
      )
    }
}

MasterTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  users: PropTypes.array.isRequired
}

MasterTable.defaultProps = {
  products: [],
  onSelect: () => { },
  onShowDetails: () => { }
}

function mapStateToProps (state) {
  return {
    products: state.products.master
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(connect(mapStateToProps)(MasterTable))
