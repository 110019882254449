import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { STATUS } from 'redux/constants'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  CircularProgress,
  withStyles
} from '@material-ui/core'
import { Portlet, PortletContent } from 'components'
import styles from './style'
import { getShopProducts, exportProduct, showAlert } from 'redux/actions'
import treeChanges from 'tree-changes'
import { getResizedImage } from 'services/utility'
import ProductDetails from 'common/ProductDetails'

const itemsPerPage = 20

class ProductList extends Component {
  state = {
    page: 0
  }

  componentDidMount = () => {
    if (!this.props.products.data.length || this.props.shopId !== this.props.products.shopId) {
      this.setState({ isLoading: true })
      this.props.dispatch(getShopProducts(this.props.shopId, 0, itemsPerPage))
    }
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('products.status', STATUS.READY)) {
      this.setState({ isLoading: false })
    }
    if (changedTo('export.status', STATUS.READY)) {
      this.props.dispatch(showAlert('Successfully exported', { variant: 'error' }))
    } else if (changedTo('export.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.export.message, { variant: 'error' }))
    }
  }

  exportToMasterTable = (product, e) => {
    e.stopPropagation()
    e.preventDefault()
    this.props.dispatch(exportProduct(this.props.shopId, product.id))
  }

  onSelectDetails = id => {
    this.setState({ isOpen: true, selectedProduct: id })
  }

  hideDetails = () => {
    this.setState({ isOpen: false, selectedProduct: null })
  }

  onReachedBottom = () => {
    const { products } = this.props
    const { isLoading, page } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    if (!isLoading && !products.reachedEnd) {
      this.setState({ page: page + 1, isLoading: true }, () => {
        this.props.dispatch(getShopProducts(this.props.shopId, nextIndex, itemsPerPage))
      })
    }
  }

  render () {
    const { classes, className, products, shopId } = this.props
    const { isOpen, selectedProduct } = this.state

    const rootClassName = classNames(classes.root, className)

    return (
      <Portlet className={rootClassName}>
        <PortletContent className={classes.container} noPadding>
          {products.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
            <CircularProgress />
          </div> : null}
          {products.data.length ? <PerfectScrollbar onYReachEnd={this.onReachedBottom}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='left' />
                  <TableCell align='left'>Name</TableCell>
                  <TableCell align='left'>Item Code</TableCell>
                  <TableCell align='left'>Model</TableCell>
                  <TableCell align='left'>Price</TableCell>
                  <TableCell align='left' />
                </TableRow>
              </TableHead>
              <TableBody>
                {products.data
                  .map(product => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={product.name}
                      onClick={() => this.onSelectDetails(product.id)}
                    >
                      <TableCell className={classes.tableCell}>
                        { product.image_url ? <img className={classes.logo} src={getResizedImage(product.image_url, 50, 50)} alt={product.name} /> : 'N/A' }
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {product.name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {product.item_code}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {product.modal}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {product.price}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Button color='primary' size='small' variant='contained' onClick={(e) => this.exportToMasterTable(product, e)} > Export </Button>
                      </TableCell>
                      {/* <TableCell className={classes.tableCell}>
                        {user.user_id || 'Not yet Registered'}
                      </TableCell> */}

                    </TableRow>
                  ))}

              </TableBody>
            </Table>
            <ProductDetails isOpen={isOpen} id={selectedProduct} shopId={shopId} isMaster={false} onCancel={this.hideDetails} onSubmit={this.hideDetails} />
          </PerfectScrollbar> : null}
          {!products.data.length && products.status === STATUS.READY
            ? <Typography
              className={classes.error}
              hover
            >No Products Found</Typography> : null}
        </PortletContent>
      </Portlet>
    )
  }
}

ProductList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  users: PropTypes.array.isRequired
}

ProductList.defaultProps = {
  products: [],
  onSelect: () => { },
  onShowDetails: () => { }
}

function mapStateToProps (state) {
  return {
    products: state.shops.products,
    export: state.shops.export
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(connect(mapStateToProps)(ProductList))
