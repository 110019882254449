/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'

/**
 * Signup
 */
export function * getCustomerList ({ payload }) {
  try {
    const { searchString } = payload
    const response = yield call(request, APIEndpoints.GET_CUSTOMERS(searchString), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_CUSTOMERS_SUCCESS,
      payload: response.items
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_CUSTOMERS_FAILURE,
      payload: err
    })
  }
}
/**
 * User Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.GET_CUSTOMERS, getCustomerList)
  ])
}
