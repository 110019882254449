export default theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    padding: theme.spacing.unit * 3
  },
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  details: {
    width: 'calc(100% -  35px)'
  },
  title: {
    fontWeight: 700,
    color: theme.palette.common.white
  },
  value: {
    color: theme.palette.common.white,
    marginTop: 10
  },
  iconWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    display: 'inline-flex',
    height: '30px',
    justifyContent: 'center',
    marginLeft: 'auto',
    width: '30px'
  },
  icon: {
    color: theme.palette.primary.main,
    width: '20px',
    height: '20px',
    fontSize: '20px'
  }
})
