export default theme => ({
  root: {
    // paddingTop: 20
  },
  container: {
    height: 'calc( 100vh - 154px )',
    overflow: 'auto'
  },
  logo: {
    height: 50,
    maxWidth: 80
  },
  tableRow: {
    height: '64px'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    padding: '14px',
    '& a': {
      textDecoration: 'none',
      color: 'black'
    },
    '&:first-child': {
      padding: '4px 14px'
    }
  },

  heading: {
    padding: '14px'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  nameText: {
    display: 'inline-block',
    marginLeft: theme.spacing.unit * 2,
    fontWeight: 500,
    cursor: 'pointer'
  },
  statusIcon: {
    width: 20,
    height: 20
  }
})
