import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import treeChanges from 'tree-changes'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  withStyles
} from '@material-ui/core'
import { Portlet, PortletContent } from 'components'
import styles from './style'
import { getShopCompletedOrders } from 'redux/actions'
import { STATUS } from 'redux/constants'
import moment from 'moment'
import OrderDetails from 'common/OrderDetails'

const itemsPerPage = 20

const statusMap = {
  confirmed: 'Pending',
  shop_confirmed: 'Confirmed',
  shop_packed: 'Packed',
  delivery: 'Out for Delivery',
  delivered: 'Delivered',
  shop_cancelled: 'Shop Cancelled',
  customer_cancelled: 'Customer Cancelled'
}

class CompletedOrderList extends Component {
  state = {
    page: 0
  }

  componentDidMount = () => {
    if (!this.props.orders.data.length || this.props.shopId !== this.props.orders.shopId) {
      this.setState({ isLoading: true })
      this.props.dispatch(getShopCompletedOrders(this.props.shopId, 0, itemsPerPage))
    }
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('orders.status', STATUS.READY)) {
      this.setState({ isLoading: false })
    }
  }

  onSelectOrder = id => {
    this.setState({ selectedOrder: id, showDetails: true })
  }

  hideDetails = () => {
    this.setState({ selectedOrder: null, showDetails: false })
  }

  onReachedBottom = () => {
    const { orders, shopId } = this.props
    const { isLoading, page } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    if (!isLoading && !orders.reachedEnd) {
      this.setState({ page: page + 1, isLoading: true }, () => {
        this.props.dispatch(getShopCompletedOrders(shopId, nextIndex, itemsPerPage))
      })
    }
  }

  render () {
    const { classes, className, orders } = this.props
    const { selectedOrder, showDetails } = this.state
    const rootClassName = classNames(classes.root, className)

    return (
      <Portlet className={rootClassName}>
        <PortletContent className={classes.container} noPadding>
          {orders.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
            <CircularProgress />
          </div> : null}
          {orders.data.length ? <PerfectScrollbar onYReachEnd={this.onReachedBottom}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>ID</TableCell>
                  <TableCell align='left'>Number of items</TableCell>
                  <TableCell align='left'>Total</TableCell>
                  <TableCell align='left'>Time</TableCell>
                  <TableCell align='left'>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.data
                  .map(order => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={order.id}
                      onClick={() => this.onSelectOrder(order.id)}
                    >
                      <TableCell className={classes.tableCell}>
                        {order.id}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {order.bill.total_products}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {parseFloat(order.bill.total_price).toFixed(2)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {moment(order.timestamp).format('YYYY-MM-D HH:mm:ss')}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {statusMap[order.status]}
                      </TableCell>
                    </TableRow>
                  ))}

              </TableBody>
            </Table>
          </PerfectScrollbar> : null}
          {!orders.data.length && orders.status === STATUS.READY
            ? <Typography
              className={classes.error}
              hover
            >No Completed Orders Found</Typography> : null}
          <OrderDetails isOpen={showDetails} orderId={selectedOrder} onSubmit={this.hideDetails} />
        </PortletContent>
      </Portlet>
    )
  }
}

CompletedOrderList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  users: PropTypes.array.isRequired
}

CompletedOrderList.defaultProps = {
  orders: [],
  onSelect: () => { },
  onShowDetails: () => { }
}

function mapStateToProps (state) {
  return {
    orders: state.shops.completedOrders
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(connect(mapStateToProps)(CompletedOrderList))
