import React from 'react'
import PropTypes from 'prop-types'
import { Stepper, Step, StepLabel, StepConnector } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'

const defaultSteps = [
  {
    status: 'confirmed',
    displayText: 'Ordered'
  },
  {
    status: 'shop_confirmed',
    displayText: 'Order Confirmed'
  },
  {
    status: 'shop_packed',
    displayText: 'Packed'
  },
  {
    status: 'shop_delivery',
    displayText: 'Out for Delivery'
  },
  {
    status: 'delivered',
    displayText: 'Delivered'
  }
]

const cancelledSteps = [
  {
    status: 'confirmed',
    displayText: 'Ordered'
  }, {
    status: 'shop_cancelled',
    displayText: 'Cancelled'
  }
]

const ProgressConnector = withStyles({
  alternativeLabel: {
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  active: {
    '& $line': {
      borderColor: 'green'
    }
  },
  completed: {
    '& $line': {
      borderColor: 'green'
    }
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: '5px'
  }
})(StepConnector)

const useProgressStepIconStyles = makeStyles({
  container: {
    paddingRight: 0,
    paddingLeft: 0
  },
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    paddingLeft: 0,
    paddingRight: 0
  },
  active: {
    color: '#784af4'
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: '4px solid green',
    backgroundColor: 'white'
  },
  completed: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: 'green'
  },
  error: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: 'red'
  },
  inactive: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: '#d8d8d8'
  }
})

function ProgressStepIcon (props) {
  const classes = useProgressStepIconStyles()
  const { active, completed, error } = props

  return (
    <div className={classes.root}>
      {completed ? (
        <div className={classes.completed} />
      ) : active ? (
        <div className={classes.circle} />
      ) : error ? (
        <div className={classes.error} />
      ) : (
        <div className={classes.inactive} />
      )}
    </div>
  )
}

const Progress = ({ status, ...rest }) => {
  const classes = useProgressStepIconStyles()
  const steps = [ ...(status === 'shop_cancelled' || status === 'customer_cancelled' ? cancelledSteps : defaultSteps) ]
  const activeStep = steps.findIndex(step => step.status === status)
  const activeStepIndex = activeStep > -1 ? activeStep : 0
  return (
    <div>
      <Stepper className={classes.container} activeStep={activeStepIndex + 1} alternativeLabel connector={<ProgressConnector />}>
        {steps.map(step => (
          <Step key={step.status}>
            <StepLabel error={step.status === 'shop_cancelled'} StepIconComponent={ProgressStepIcon}>{step.displayText}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

Progress.propTypes = {
  count: PropTypes.string
}

Progress.defaultProps = {}

export default Progress
