
export default theme => ({
  container: {
    maxHeight: '70vh',
    overflow: 'scroll'
  },
  imageContainer: {
    width: '100%',
    maxHeight: 300,
    display: 'flex',
    justifyContent: 'space-around'
  },
  image: {
    maxHeight: 300,
    maxWidth: 300,
    objectFit: 'contain',
    border: '1px solid gray'
  },
  list: {
    display: 'flex',
    justifyContent: 'center',
    width: 140
  },
  imageListContainer: {
    alignItems: 'center',
    width: 100,
    overflow: 'auto',
    maxHeight: 300,
    padding: '0 10px',
    border: '1px solid gray',
    '&::-webkit-scrollbar': { width: '0 !important' }
  },
  miniImage: {
    height: 80,
    width: 80,
    margin: '10px 0',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  selected: {
    border: '1px solid red'
  },
  details: {
    marginTop: 20
  },
  detailsCell: {
    display: 'flex',
    marginBottom: 13
  },
  key: {
    width: 150,
    fontSize: 16
  },
  value: {
    fontWeight: 500,
    marginLeft: 10,
    flex: 1,
    color: 'black',
    fontSize: 16,
    lineHeight: '18px'
  },
  footer: {
    width: '100%',
    padding: '15px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '1px 0px 8px -5px #888888'
  },
  stock: {
    width: 100,
    fontWeight: 500,
    fontSize: 13,
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'space-between'
  },
  stockHeading: {
    width: 50,
    lineHeight: '38px',
    fontSize: 15,
    fontWeight: 'bold'
  },
  switch: {
    color: '#7d58ff',
    '&$checked': {
      color: '#7d58ff'
    },
    '&$checked + $track': {
      backgroundColor: '#7d58ff'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 150
  },
  progressWrapper: {
    position: 'absolute',
    top: 160,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center'
  }

})
