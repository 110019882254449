export default theme => ({
  root: {
    padding: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: 0
  },
  container: {
    display: 'flex',
    position: 'relative'
  },
  item: {
    height: '100%'
  },
  progressWrapper: {
    zIndex: 10,
    width: '100%',
    position: 'absolute',
    top: '500',
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center'
  },
  tabs: {
    width: '100%'
  },
  tab: {
    minHeight: 48,
    width: 180,
    '& span': {
      display: 'flex',
      flexDirection: 'row',
      '& svg': {
        marginRight: 10
      }
    }
  }
})
