export default theme => ({
  root: {
    borderColor: theme.palette.primary.main,
    padding: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontWeight: 700,
    color: theme.palette.common.white
  },
  iconWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    display: 'inline-flex',
    height: '30px',
    justifyContent: 'center',
    marginLeft: 'auto',
    width: '30px'
  },
  icon: {
    color: theme.palette.primary.main,
    width: '20px',
    height: '20px',
    fontSize: '20px'
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    '&:last-child': {
      marginBottom: 0
    },
    '&:first-child p': {
      color: 'green',
      fontSize: 16
    },
    '&:nth-child(2) p': {
      color: '#da7171'
    },
    '&:nth-child(3) p': {
      color: '#0767DB;'
    },
    '&:nth-child(4) p': {
      color: '#076E2B;'
    },
    '&:nth-child(5) p': {
      color: '#6e2907;'
    }
  }
})
