import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid, withStyles, Tabs, Tab } from '@material-ui/core'
import { Dashboard as DashboardLayout } from 'layouts'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import AssignmentIcon from '@material-ui/icons/Assignment'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import SettingsIcon from '@material-ui/icons/Settings'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import TimelineIcon from '@material-ui/icons/Timeline'
// import treeChanges from 'tree-changes'

import style from './style'
import { OrderList, CompletedOrderList, ProductList, Details, Settings, SlotSettings, Analytics } from './components'

class ShopDetails extends Component {
  state = {
    stage: 0
  }

  componentDidMount = () => {
    const { match } = this.props
    const { id } = match.params
    this.setState({ shopId: id })
  }

  handleChange = (event, stage) => {
    this.setState({ stage })
  }

  render () {
    const { classes } = this.props
    const { stage, shopId } = this.state

    return (
      <DashboardLayout title='Shops'>
        <div className={classes.root}>
          <Grid
            container
            className={classes.container}
            spacing={4}
          >
            <Tabs
              value={stage}
              className={classes.tabs}
              onChange={this.handleChange}
              indicatorColor='primary'
              textColor='primary'
              aria-label='icon tabs example'
            >
              <Tab className={classes.tab} icon={<AssignmentIcon />} label='Details' aria-label='details' />
              <Tab className={classes.tab} icon={<TimelineIcon />} label='Analytics' aria-label='details' />
              <Tab className={classes.tab} icon={<AssignmentIcon />} label='Orders' aria-label='orders' />
              <Tab className={classes.tab} icon={<ShoppingBasketIcon />} label='Products' aria-label='products' />
              <Tab className={classes.tab} icon={<AssignmentTurnedInIcon />} label='Completed Orders' aria-label='completed' />
              <Tab className={classes.tab} icon={<SettingsIcon />} label='Settings' aria-label='Settings' />
              <Tab className={classes.tab} icon={<SettingsIcon />} label='Slot Settings' aria-label='Slot Settings' />
            </Tabs>
            {stage === 0 ? <Details shopId={shopId} /> : null}
            {stage === 1 ? <Analytics shopId={shopId} /> : null}
            {stage === 2 ? <OrderList shopId={shopId} /> : null}
            {stage === 3 ? <ProductList shopId={shopId} /> : null}
            {stage === 4 ? <CompletedOrderList shopId={shopId} /> : null}
            {stage === 5 ? <Settings shopId={shopId} /> : null}
            {stage === 6 ? <SlotSettings shopId={shopId} /> : null}
          </Grid>
        </div>
      </DashboardLayout>
    )
  }
}

ShopDetails.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    shopDetails: state.shops.details,
    verify: state.shops.verify,
    delete: state.shops.delete
  }
}

export default compose(
  withRouter,
  withStyles(style)
)(connect(mapStateToProps)(ShopDetails))
