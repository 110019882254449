import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

// Views
import PrivateRoute from './views/PrivateRoute'
import PublicRoute from './views/PublicRoute'

import Dashboard from './views/Dashboard'
import Shops from './views/Shops'
import SignIn from './views/SignIn'
import NotFound from './views/NotFound'
import ShopDetails from 'views/ShopDetails'
import Locations from 'views/Locations'
import NewShops from 'views/NewShops'
import CompletedOrders from 'views/CompletedOrders'
import ActiveOrders from 'views/ActiveOrders'
import MasterTable from 'views/MasterTable'
import OrderDetails from 'views/OrderDetails'

const states = [
  '/shops',
  '/new-shops',
  '/dashboard',
  '/locations',
  '/active-orders',
  '/completed-orders',
  '/master-table',
  '/order'
]
const defaultState = '/dashboard'

class Routes extends Component {
  constructor (props) {
    super(props)
    const loadedRoute = window.location.pathname
    let index = states.indexOf(loadedRoute)
    if (loadedRoute.includes('/shops/') || loadedRoute.includes('/order/')) {
      index = 1
    }
    this.state = {
      defaultPage: index > -1 ? loadedRoute : defaultState
    }
  }

  render () {
    const { defaultPage } = this.state
    const { user } = this.props

    return (
      <Switch>
        <Redirect
          exact
          from='/'
          to={defaultState}
        />
        <PublicRoute
          isAuthenticated={user.isAuthenticated}
          to={defaultPage}
          path='/sign-in'
          exact
          component={SignIn}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/dashboard'
          to='/sign-in'
          exact
          component={Dashboard}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/shops'
          to='/sign-in'
          exact
          component={Shops}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/new-shops'
          to='/sign-in'
          exact
          component={NewShops}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/shops/:id'
          to='/sign-in'
          exact
          component={ShopDetails}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/locations'
          to='/sign-in'
          exact
          component={Locations}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/active-orders'
          to='/sign-in'
          exact
          component={ActiveOrders}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/order/:id'
          to='/sign-in'
          exact
          component={OrderDetails}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/completed-orders'
          to='/sign-in'
          exact
          component={CompletedOrders}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/master-table'
          to='/sign-in'
          exact
          component={MasterTable}
        />
        <Route
          component={NotFound}
          exact
          path='/not-found'
        />
        <Redirect to='/not-found' />
      </Switch>
    )
  }
}

/* istanbul ignore next */
function mapStateToProps (state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(Routes)
