/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'

/**
 * Signup
 */
export function * getMasterTableItems ({ payload }) {
  try {
    const { searchString, skip, limit } = payload
    const response = yield call(request, APIEndpoints.GET_MASTER_TABLE_ITEMS(skip, limit, searchString), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_MASTER_TABLE_ITEMS_SUCCESS,
      payload: response.master_items
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_MASTER_TABLE_ITEMS_FAILURE,
      payload: err
    })
  }
}

export function * getProductDetails ({ payload }) {
  try {
    const { id, isMaster, shopId } = payload
    const response = yield call(request, APIEndpoints.GET_PRODUCT_DETAILS(id, isMaster, shopId), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_PRODUCT_DETAILS_SUCCESS,
      payload: response.item
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_PRODUCT_DETAILS_FAILURE,
      payload: err
    })
  }
}
/**
 * User Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.GET_MASTER_TABLE_ITEMS, getMasterTableItems),
    takeLatest(ActionTypes.GET_PRODUCT_DETAILS, getProductDetails)
  ])
}
