import React, { Component } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import compose from 'recompose/compose'

import { Typography, withStyles, Button } from '@material-ui/core'
import { Portlet, PortletContent, PortletFooter } from 'components'
import style from './style'

class ShopAddress extends Component {
  handleDeleteAction = (shop) => {
    this.props.deleteShop(shop)
  }

  render () {
    const { classes, className, shop, ...rest } = this.props
    const rootClassName = classNames(classes.root, className)

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletContent>
          <div className={classes.details}>
            {shop.address ? <div className={classes.info}>
              <Typography
                variant='h5'
              >
                {shop.contact.customer_care && shop.contact.phone ? `${shop.contact.customer_care}, ${shop.contact.phone}` : shop.contact.customer_care || shop.contact.phone }
              </Typography>
              <Typography
                className={classes.dateText}
                variant='body1'
              >
                {shop.contact.email}
              </Typography>
              <Typography
                className={classes.dateText}
                variant='body1'
              >
                {shop.address.formatted_address}
              </Typography>
              <Typography
                className={classes.locationText}
                variant='body1'
              >
                {shop.address.address}
              </Typography>
              <Typography
                className={classes.dateText}
                variant='body1'
              >
                {shop.address.landmark}
              </Typography>
              <Typography
                className={classes.dateText}
                variant='body1'
              >
                {shop.address.locality}
              </Typography>

            </div> : null }
          </div>
        </PortletContent>
        <PortletFooter className={classes.portletFooter} >
          <Button
            color='primary'
            variant='contained'
            onClick={() => this.handleDeleteAction(shop)}
          >
            Delete
          </Button>
        </PortletFooter>
      </Portlet>
    )
  }
}

ShopAddress.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

export default compose(
  withStyles(style)
)(ShopAddress)
