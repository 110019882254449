import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
// Externals
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Button, withStyles, Popover, Grid, TextField } from '@material-ui/core'
import { SearchInput } from 'components'

// Component styles
import styles from './style'

class SearchToolbar extends Component {
  state = {
    openFilter: null,
    startingDate: null,
    endingDate: null
  }

  keyPressed = event => {
    if (event.key === 'Enter') {
      this.props.onClickSearch()
    }
  }

  handleOpenFilter = (e) => {
    this.setState({ openFilter: e.currentTarget })
  }

  addHoursToDate = (prevDate) => {
    return new Date(prevDate).setHours(23, 59, 59, 0)
  }

  getTimeStamp = (date) => {
    var myDate = date
    myDate = myDate.split('-')
    var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2])
    return newDate.getTime()
  }

  handlefilter = () => {
    const { onClickFilter } = this.props
    const { startingDate, endingDate } = this.state
    if (startingDate && endingDate) {
      this.setState({ openFilter: null })
      this.props.history.push({
        search: '?starting=' + this.getTimeStamp(startingDate) + '&ending=' + this.addHoursToDate(endingDate)
      })
      onClickFilter(this.getTimeStamp(startingDate), this.addHoursToDate(endingDate))
    }
  }

  render () {
    const { classes, className, searchString, onChangeSearchString, onClickSearch, onClickRefreshButton } = this.props

    const rootClassName = classNames(classes.root, className)

    const open = Boolean(this.state.openFilter)
    const id = open ? 'simple-popover' : undefined

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            placeholder='Search'
            value={searchString}
            onKeyPress={this.keyPressed}
            onChange={onChangeSearchString}
          />
          <Button color='primary' size='small' variant='outlined' onClick={onClickSearch} >
            Search
          </Button>
          <div>
            <Button className={classes.dateFilterButton} color='primary' size='small' variant='outlined' onClick={this.handleOpenFilter}>
              Filter
            </Button>
            <Popover
              id={id}
              open={id}
              anchorEl={this.state.openFilter}
              onClose={this.handleCloseFilter}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              className={classes.dateFilterContainer}
            >
              <Grid container spacing={2} alignItems='center'>
                <Grid xs={5} item>
                  <TextField
                    id='date'
                    label='Starting Date'
                    type='date'
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.startingDate}
                    onChange={e => this.setState({ startingDate: e.target.value })}
                  />
                </Grid>
                <Grid xs={5} item>
                  <TextField
                    id='date'
                    label='Ending Date'
                    type='date'
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.endingDate}
                    onChange={e => this.setState({ endingDate: e.target.value })}
                  />
                </Grid>
                <Grid xs={2} item>
                  <Button color='primary' size='small' variant='contained' onClick={this.handlefilter} disableElevation>
                    Done
                  </Button>
                </Grid>
              </Grid>
            </Popover>
          </div>
          <span className={classes.spacer} />
          <Button color='primary' size='small' variant='outlined' onClick={onClickRefreshButton} >
            Refresh
          </Button>
        </div>
      </div>
    )
  }
}

SearchToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

export default (withRouter(withStyles(styles)(SearchToolbar)))
