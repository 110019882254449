import React, { Component } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'
import { withStyles, Typography, Button } from '@material-ui/core'
import { SlotListCard, AddSlot, DeleteSlotConfirmation } from '..'
import { showAlert, deleteShopSlot } from 'redux/actions/index'
import { STATUS } from 'redux/constants'

// Component styles
const styles = theme => ({
  wrapper: {
    height: 'calc( 100vh - 124px )',
    minWidth: 356,
    width: 'calc( 100% - 120px )',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[2],
    borderRadius: 4,
    marginRight: 30,
    background: '#fff'
  },
  header: {
    height: 60,
    padding: 20,
    boxShadow: '1px 2px 8px -5px #888888',
    position: 'relative'
  },
  heading: {
    width: 'calc( 100% - 80px )'
  },
  button: {
    position: 'absolute',
    top: 15,
    right: 20
  },
  list: {
    height: 'calc( 100% - 60px )',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  none: {
    marginTop: 20,
    width: '100%',
    textAlign: 'center'
  },
  loading: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: 40
  }
})

class SlotList extends Component {
  state = {
    showAddNew: false
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('deleteSlot.status', STATUS.READY)) {
      this.setState({ showDelete: false, selected: null })
      this.props.dispatch(showAlert('Deleted slot successfully', { variant: 'success' }))
    } else if (changedTo('deleteSlot.status', STATUS.ERROR)) {
      this.setState({ showDelete: false, selected: null })
      this.props.dispatch(showAlert('Failed to delete slot successfully. Try again later', { variant: 'error' }))
    }
  }

  onEdit = (e, item) => {
    e.stopPropagation()
    this.setState({ showAddNew: true, selectedEdit: item })
  }

  onDelete = item => {
    this.setState({ showDelete: true, selected: item })
  }

  onHideDelete = () => {
    this.setState({ showDelete: false, selected: null })
  }

  onSubmitDelete = () => {
    this.props.dispatch(deleteShopSlot(this.props.shopId, this.state.selected.id))
  }

  showAddNew = () => {
    this.setState({ showAddNew: true, selectedEdit: null })
  }

  onSubmit = () => {
    this.setState({ showAddNew: false, selectedEdit: null })
  }

  render () {
    const { classes, heading, slots, isButtonDisabled, slotDay, shopId } = this.props
    const { showAddNew, selectedEdit, showDelete, selected } = this.state
    const isEmpty = !slots.length
    return (
      <div className={classes.wrapper}>
        <div className={classes.header} >
          <Typography className={classes.heading} variant='h4' >{heading}</Typography>
          <Button className={classes.button} disabled={isButtonDisabled} color='primary' size='small' variant='outlined' onClick={this.showAddNew} > Add New </Button>
        </div>
        <div className={classes.list} >
          { slots.map(item => <SlotListCard key={item.id} item={item} onDelete={() => this.onDelete(item)} onEdit={(e) => this.onEdit(e, item)} />) }
          { isEmpty ? <Typography className={classes.none} variant='h6' >No slots found</Typography> : null}
        </div>
        <DeleteSlotConfirmation isOpen={showDelete} slot={selected} onCancel={this.onHideDelete} onSubmit={this.onSubmitDelete} />
        <AddSlot isOpen={showAddNew} slotDay={slotDay} shopId={shopId} onCancel={this.onSubmit} onSubmit={this.onSubmit} details={selectedEdit || null} slotId={selectedEdit ? selectedEdit.id : null} />
      </div>
    )
  }
}

SlotList.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    deleteSlot: state.shops.deleteSlot
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(SlotList))
