import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import { REHYDRATE } from 'redux-persist/lib/constants'

import { ActionTypes } from 'redux/constants/index'

export const appState = {
  alert: {
    data: {},
    message: '',
    showAlert: false
  }
}

export default {
  app: handleActions(
    {
      [REHYDRATE]: state =>
        immutable(state, {
          alert: {
            data: { $set: {} },
            message: { $set: '' },
            showAlert: { $set: false }
          }
        }),
      [ActionTypes.HIDE_ALERT]: (state, { payload }) => {
        // const alerts = state.alerts.filter(d => d.id !== id);
        return immutable(state, {
          alert: {
            data: { $set: {} },
            message: { $set: '' },
            showAlert: { $set: false }
          }
        })
      },
      [ActionTypes.SHOW_ALERT]: (state, { payload }) =>
        immutable(state, {
          alert: {
            data: { $set: payload },
            message: { $set: payload.message },
            showAlert: { $set: true }
          }
        })
    },
    appState
  )
}
