/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'

/**
 * Signup
 */
export function * getShopList ({ payload }) {
  try {
    const { searchString, skip, limit } = payload
    const response = yield call(request, APIEndpoints.GET_SHOPS(skip, limit, searchString), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_SHOPS_SUCCESS,
      payload: response.shops
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SHOPS_FAILURE,
      payload: err
    })
  }
}

export function * exportProduct ({ payload }) {
  try {
    const { id, shopId } = payload
    const response = yield call(request, APIEndpoints.EXPORT_PRODUCT(shopId, id), {
      method: 'POST',
      payload: { item_id: id }
    })
    yield put({
      type: ActionTypes.EXPORT_PRODUCT_SUCCESS,
      payload: response.shops
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.EXPORT_PRODUCT_FAILURE,
      payload: err
    })
  }
}

export function * getNewShopList ({ payload }) {
  try {
    const { searchString, skip, limit } = payload
    const response = yield call(request, APIEndpoints.GET_NEW_SHOPS(skip, limit, searchString), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_NEW_SHOPS_SUCCESS,
      payload: response.shops
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_NEW_SHOPS_FAILURE,
      payload: err
    })
  }
}

export function * getShopLocation ({ payload }) {
  try {
    const response = yield call(request, APIEndpoints.GET_SHOP_LOCATION, {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_SHOP_LOCATION_SUCCESS,
      payload: response.shops
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SHOP_LOCATION_FAILURE,
      payload: err
    })
  }
}

export function * getShopDetails ({ payload }) {
  try {
    const { id } = payload
    const response = yield call(request, APIEndpoints.GET_SHOP_DETAILS(id), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_SHOP_DETAILS_SUCCESS,
      payload: response.shop
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SHOP_DETAILS_FAILURE,
      payload: err
    })
  }
}

export function * getShopProducts ({ payload }) {
  try {
    const { id, skip, limit } = payload
    const response = yield call(request, APIEndpoints.GET_SHOP_PRODUCTS(id, skip, limit), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_SHOP_PRODUCTS_SUCCESS,
      payload: response.items
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SHOP_PRODUCTS_FAILURE,
      payload: err
    })
  }
}

export function * getShopActiveOrders ({ payload }) {
  try {
    const { id, skip, limit } = payload
    const response = yield call(request, APIEndpoints.GET_SHOP_ACTIVE_ORDERS(id, skip, limit), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_SHOP_ACTIVE_ORDERS_SUCCESS,
      payload: response.orders
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SHOP_ACTIVE_ORDERS_FAILURE,
      payload: err
    })
  }
}

export function * getShopCompletedOrders ({ payload }) {
  try {
    const { id, skip, limit } = payload
    const response = yield call(request, APIEndpoints.GET_SHOP_COMPLETED_ORDERS(id, skip, limit), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_SHOP_COMPLETED_ORDERS_SUCCESS,
      payload: response.orders
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SHOP_COMPLETED_ORDERS_FAILURE,
      payload: err
    })
  }
}

export function * verifyShop ({ payload }) {
  try {
    const { id, status } = payload
    yield call(request, APIEndpoints.VERIFY_SHOP(id, status), {
      method: 'POST',
      payload: {}
    })
    yield put({
      type: ActionTypes.VERIFY_SHOP_SUCCESS,
      payload: { id, status }
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.VERIFY_SHOP_FAILURE,
      payload: err
    })
  }
}

export function * deleteShop ({ payload }) {
  try {
    const { id } = payload
    yield call(request, APIEndpoints.DELETE_SHOP(id), {
      method: 'DELETE',
      payload: {}
    })
    yield put({
      type: ActionTypes.DELETE_SHOP_SUCCESS,
      payload: id
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.DELETE_SHOP_FAILURE,
      payload: err
    })
  }
}

export function * getShopSettings ({ payload }) {
  try {
    const { id } = payload
    const response = yield call(request, APIEndpoints.GET_SHOP_SETTINGS(id), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_SHOP_SETTINGS_SUCCESS,
      payload: response.shop
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SHOP_SETTINGS_FAILURE,
      payload: err
    })
  }
}

export function * updateShopSettings ({ payload }) {
  try {
    const { id, requestObj } = payload
    const response = yield call(request, APIEndpoints.UPDATE_SHOP_SETTINGS(id), {
      method: 'POST',
      payload: requestObj
    })
    yield put({
      type: ActionTypes.UPDATE_SHOP_SETTINGS_SUCCESS,
      payload: response.shop
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_SHOP_SETTINGS_FAILURE,
      payload: err
    })
  }
}

export function * addShopLogo ({ payload }) {
  try {
    const { url, id } = payload
    yield call(request, APIEndpoints.EDIT_SHOP_LOGO(id), {
      method: 'POST',
      payload: {
        logo_url: url
      }
    })
    yield put({
      type: ActionTypes.EDIT_SHOP_LOGO_SUCCESS,
      payload: { url }
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.EDIT_SHOP_LOGO_FAILURE,
      payload: err
    })
  }
}

export function * getShopSlots ({ payload }) {
  try {
    const { id } = payload
    const response = yield call(request, APIEndpoints.GET_SHOP_SLOTS(id), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_SHOP_SLOTS_SUCCESS,
      payload: response.slots
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SHOP_SLOTS_FAILURE,
      payload: err
    })
  }
}

export function * getShopSlotDetails ({ payload }) {
  try {
    const { id, shopId } = payload
    const response = yield call(request, APIEndpoints.GET_SHOP_SLOT_DETAILS(shopId, id), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_SHOP_SLOT_DETAILS_SUCCESS,
      payload: response.slot
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SHOP_SLOT_DETAILS_FAILURE,
      payload: err
    })
  }
}

export function * updateShopSlot ({ payload }) {
  try {
    const { id, shopId, requestObj } = payload
    const response = yield call(request, APIEndpoints.UPDATE_SHOP_SLOT(shopId, id), {
      method: 'POST',
      payload: { slot: requestObj }
    })
    yield put({
      type: ActionTypes.UPDATE_SHOP_SLOT_SUCCESS,
      payload: response.slot
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_SHOP_SLOT_FAILURE,
      payload: err
    })
  }
}

export function * addShopSlot ({ payload }) {
  try {
    const { shopId, requestObj } = payload
    const response = yield call(request, APIEndpoints.ADD_SHOP_SLOT(shopId), {
      method: 'POST',
      payload: requestObj
    })
    yield put({
      type: ActionTypes.ADD_SHOP_SLOT_SUCCESS,
      payload: response.slot
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_SHOP_SLOT_FAILURE,
      payload: err
    })
  }
}

export function * deleteShopSlot ({ payload }) {
  try {
    const { shopId, id } = payload
    yield call(request, APIEndpoints.DELETE_SHOP_SLOT(shopId, id), {
      method: 'DELETE'
    })
    yield put({
      type: ActionTypes.DELETE_SHOP_SLOT_SUCCESS,
      payload: { id }
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.DELETE_SHOP_SLOT_FAILURE,
      payload: err
    })
  }
}
export function * getShopAnalytics ({ payload }) {
  const { shopId } = payload
  try {
    const response = yield call(request, APIEndpoints.GET_SHOP_ANALYTICS(shopId), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_SHOP_ANALYTICS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SHOP_ANALYTICS_FAILURE,
      payload: err
    })
  }
}

/**
 * User Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.GET_SHOPS, getShopList),
    takeLatest(ActionTypes.GET_NEW_SHOPS, getNewShopList),
    takeLatest(ActionTypes.GET_SHOP_DETAILS, getShopDetails),
    takeLatest(ActionTypes.GET_SHOP_PRODUCTS, getShopProducts),
    takeLatest(ActionTypes.GET_SHOP_ACTIVE_ORDERS, getShopActiveOrders),
    takeLatest(ActionTypes.GET_SHOP_COMPLETED_ORDERS, getShopCompletedOrders),
    takeLatest(ActionTypes.VERIFY_SHOP, verifyShop),
    takeLatest(ActionTypes.GET_SHOP_LOCATION, getShopLocation),
    takeLatest(ActionTypes.DELETE_SHOP, deleteShop),
    takeLatest(ActionTypes.EXPORT_PRODUCT, exportProduct),
    takeLatest(ActionTypes.GET_SHOP_SETTINGS, getShopSettings),
    takeLatest(ActionTypes.UPDATE_SHOP_SETTINGS, updateShopSettings),
    takeLatest(ActionTypes.EDIT_SHOP_LOGO, addShopLogo),
    takeLatest(ActionTypes.GET_SHOP_SLOTS, getShopSlots),
    takeLatest(ActionTypes.GET_SHOP_SLOT_DETAILS, getShopSlotDetails),
    takeLatest(ActionTypes.UPDATE_SHOP_SLOT, updateShopSlot),
    takeLatest(ActionTypes.ADD_SHOP_SLOT, addShopSlot),
    takeLatest(ActionTypes.DELETE_SHOP_SLOT, deleteShopSlot),
    takeLatest(ActionTypes.GET_SHOP_ANALYTICS, getShopAnalytics)
  ])
}
