import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Grid, withStyles, CircularProgress, Typography, Button } from '@material-ui/core'
import { Dashboard as DashboardLayout } from 'layouts'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import { STATUS } from 'redux/constants/index'

import { getShopLocation } from 'redux/actions'
import style from './style'
import MapContainer from './MapContainer'
import Select from 'components/Select'

const shopStatusList = [{
  id: 'all',
  value: 'All'
}, {
  id: 'verified',
  value: 'Verified'
}, {
  id: 'pending',
  value: 'Pending'
}
]
const acceptStatusList = [{
  id: 'all',
  value: 'All'
}, {
  id: 'accepting',
  value: 'Accepting Orders'
}, {
  id: 'pending',
  value: 'Not Accepting Orders'
}
]

class Locations extends Component {
  state = {
    searchString: '',
    page: 0,
    selectedShop: undefined,
    accept: 'all',
    status: 'all'
  }

  componentDidMount = () => {
    const { status, accept } = this.state
    if (this.props.shops.status === STATUS.IDLE) {
      this.lastSearch = { status, accept }
      this.props.dispatch(getShopLocation(status, accept))
    }
  }

  onSelectShop = (selectedShop) => {
    this.setState({ selectedShop })
  }

  gotoShop = (id) => {
    window.open(`/shops/${id}`)
  }

  handleFieldChange = (field, value) => {
    this.setState({ [field]: value })
  }

  onClickSearch = () => {
    const { status, accept } = this.state
    if (this.lastSearch.accept !== accept || this.lastSearch.status !== status) {
      this.props.dispatch(getShopLocation(status, accept))
    }
  }

  getShopPositions = () => {
    const { shops, classes } = this.props
    const { selectedShop } = this.state
    if (shops.status === STATUS.RUNNING && shops.data.length === 0) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )
    }

    if (shops.data.length === 0) {
      return (
        <Typography variant='h6'>There are no shops found</Typography>
      )
    }
    return (
      <React.Fragment>
        <MapContainer
          isMarkerShown
          googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyDGDfRCav9eLb0oZXtDWlsjFArqlB3xaxw&v=3.exp&v=3.exp'
          // googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBujK5bEmXsfoAFjcA2rmF6PFBSoHziQ-I&v=3.exp&v=3.exp'
          loadingElement={<div style={{ height: '300px' }} />}
          containerElement={<div className={classes.mapContainer} style={{ height: 'calc(100vh - 160px)', width: '100%' }} />}
          mapElement={<div style={{ height: 'calc(100vh - 160px)' }} />}
          shops={shops.data}
          selectedShop={selectedShop}
          onSelectShop={this.onSelectShop}
          gotoShop={this.gotoShop}
        />
      </React.Fragment>
    )
  }

  render () {
    const { classes } = this.props
    const { status, accept } = this.props
    return (
      <DashboardLayout title='Locations'>
        <div className={classes.root}>
          <Grid
            container
            className={classes.container}
            spacing={4}
          >
            <div className={classes.filterContainer} >
              <Select placeholder='Shop Status' options={shopStatusList} valueParam='value' keyParam='id' selectedValue={status} handleChange={(event) => this.handleFieldChange('status', event.target.value)} />
              <Select placeholder='Accept Status' options={acceptStatusList} valueParam='value' keyParam='id' selectedValue={accept} handleChange={(event) => this.handleFieldChange('accept', event.target.value)} />
              <Button className={classes.searchButton} color='primary' size='small' variant='outlined' onClick={this.onClickSearch} >
                Search
              </Button>
            </div>
            {this.getShopPositions()}
          </Grid>
        </div>
        {/* <AddPartner isOpen={showAddDeliveryPerson} onCancel={this.hideAddDeliveryPerson} onSubmit={this.onSubmitPartner} /> */}
      </DashboardLayout>
    )
  }
}

Locations.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    shops: state.shops.locations
  }
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(Locations))
